<template>
  <div class="module-dialog">
    <znl-table
      ref="table"
      gridtype="base"
      box-class="module-dialog"
      :columns="columns"
      :item-source="itemSource"
      v-loading="loading"
      :element-loading-text="loadingText"
    ></znl-table>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as common } from "~assets/scripts/methods/common";

const CONFIG = {
  searchURL: "Inquire/Search"
};

const gridCols = [
  {
    CTitle: "型号",
    BindField: "Model",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 130,
    DataType: 1
  },
  {
    CTitle: "品牌",
    BindField: "Brand",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "封装",
    BindField: "Packaging",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "年份",
    BindField: "MakeYear",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "询价量",
    BindField: "Qty",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "库存量",
    BindField: "InvQty",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "单价",
    BindField: "Price",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "供应商名称",
    BindField: "SupplierName",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 130,
    DataType: 1
  },
  {
    CTitle: "询价时间",
    BindField: "CreatedTime",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "询价员",
    BindField: "CreatedName",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "币种",
    BindField: "CurrencyName",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "包装",
    BindField: "MPQ",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "品质",
    BindField: "Quality",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  }
];

export default {
  name: "taobao_InquireList",
  mixins: [getCommonDataMixin, common],
  config: CONFIG,
  components: {},

  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {
        InquireGUIDS: "" // 询价明细GUID
      },
      loading: false,
      loadingText: "数据加载中,请稍后..."
    };
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    InquireGUIDS: {
      type: Array
    }
  },

  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = {
          ColumnConfigs: gridCols
        };
      }
      let columns = config.ColumnConfigs;
      this.columns = columns;

      this.onInitCols(columns);

      this.$refs.table.init();
      this.$nextTick(() => {
        this.onBindData();
        this.$emit("page-loading", false);
      });
    },

    onInitCols(columns) {},

    // 搜索 绑定数据
    async onBindData(pageIndex = 1) {
      if (
        this.InquireGUIDS == undefined ||
        this.InquireGUIDS == null ||
        this.InquireGUIDS.leng < 0
      ) {
        return;
      }
      this.loading = true;
      let where = {
        IQGUIDS: this.InquireGUIDS
      };
      return await this.$post(CONFIG.searchURL, where, (result, logic) => {
        if (logic.code === 200) {
          this.itemSource = result.ResultList;
        }
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    }
  }
};
</script>

<style lang="scss">
</style>

