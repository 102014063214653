<template>
  <div class="module-dialog">
    <znl-table
      ref="table"
      gridtype="base"
      :showCheck="true"
      checkboxBindingKey="StkGUID"
      box-class="module-dialog"
      :columns="columns"
      :item-source="itemSource"
      v-loading="loading"
      :element-loading-text="loadingText"
    ></znl-table>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
const CONFIG = {
  searchURL: "TaobaoOrder/GetLinedStockList",
  configURL: "StkStock/GetConfig"
};

export default {
  name: "LinkedStockList",
  mixins: [getCommonDataMixin],
  config: CONFIG,
  components: {},
  data() {
    return {
      isCheck: false,
      isZero: false,
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      inputModel: "",
      searchFields: {
        Model: ""
      },
      IsOutQty: true,
      GCode: "StkStock",
      loading: false,
      loadingText: "数据加载中,请稍后...",
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  watch: {},
  computed: {},
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    oidStr: {
      type: String,
      default: ""
    }
  },
  methods: {
    onInit: async function() {
      this.$emit("page-loading", true);
      let config = await this.isSaveStorage("stkStockCheck", CONFIG.configURL);

      let columns = config.ColumnConfigs;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;

      this.resourceList = config.ResourceList;

      columns.unshift({
        ColumnWidth: 70,
        BindField: "LinkedStockQty",
        CTitle: "已配货量",
        isFrozen: true,
        IsReadonly: true,
        IsRequired: false,
        DataType: 2
      });
      // columns.unshift({
      //   width: 40,
      //   type: "button",
      //   title: "操作",
      //   btnTxt: "取消",
      //   isFrozen: true,
      //   click: row => {}
      // });
      if (!this.IsMainAccount) {
        this.setHideColumns(columns);
      }
      this.columns = columns;
      this.pageSize = config.PageSize;

      this.$refs.table.init();
      this.$nextTick(() => {
        if (this.initData) {
          this.onBindData();
        }
        this.loading = false;
        this.$emit("page-loading", false);
      });
    },
    //  /** **********权限控制start*************/
    setHideColumns(columns) {
      // 成本价权限控制BuyPrice 采购价未税BuyPrice 和TaxBuyPrice 采购价(含税)  和 成本金额TotalCostAmount
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");

      // 成本价权限控制 SalesPrice 销售定价 FollowPrice 建议销售价
      let viewSalesPrice = this.getSpecialResourceByCode("ViewSalesPrice");

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");

      _.remove(columns, item => {
        if (item.BindField === "StockImage" || item.BindField === "AdoptQty") {
          return true;
        }

        // 不看成本价
        if (!viewBuyPrice) {
          switch (item.BindField) {
            case "BuyPrice": // 未税采购价
            case "TaxBuyPrice": // 含税采购价
            case "TotalCostAmount":
            case "CostAmountInTax":
              return true;
          }
        }
        // 不看销售价
        if (
          !viewSalesPrice &&
          (item.BindField === "SalesPrice" || item.BindField === "FollowPrice")
        ) {
          return true;
        }
        // 不看供应商
        if (noSeeSupplier && item.BindField === "SupplierName") {
          return true;
        }
        // 看备注1 没有这个权限隐藏列
        if (item.BindField === "Remark" && !this.hasRes("ViewRemark1")) {
          return true;
        }
        // 看备注2 没有这个权限隐藏列
        if (item.BindField === "Remark1" && !this.hasRes("ViewRemark2")) {
          return true;
        }
        // 看备注3 没有这个权限隐藏列
        if (item.BindField === "Remark2" && !this.hasRes("ViewRemark3")) {
          return true;
        }
        // 不看替代型号，有这个权限隐藏列
        if (
          item.BindField === "ReplaceModel" &&
          this.hasRes("NoSeeReplaceModel")
        ) {
          return true;
        }
      });
    },
    async onBindData(pageIndex = 1) {
      let data = { OidStr: this.oidStr };

      this.loading = true;
      return this.$post(CONFIG.searchURL, data, (res, logic) => {
        if (logic.code === 200) {
          this.itemSource = res === undefined ? [] : res;
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 300);
        this.$emit("page-loading", false);
        // 要放nextTick函数里面执行
        this.$nextTick(() => {});
      });
    },

    onSearch(params) {
      this.searchFields = _.extend({}, this.searchFields, params);
      return this.onBindData(1);
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    onGetSelectedRows() {
      return this.$refs.table.getCheckedRows();
    }
  },
  async mounted() {
    await this.onInit();
  }
};
</script>

<style lang="scss">
</style>
