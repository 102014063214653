<template>
  <!-- 电子面单弹窗 -->
  <div>
    <znl-dialog
      title="电子面单"
      :visible="visible"
      subhead
      width="720px"
      height="420px"
      class="dialog-eexpress-order-edit-box"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      element-loading-text="正在操作，请稍后..."
      @close="close"
    >
      <express-order-edit
        :order-guid="orderGuid"
        :order-no="orderNo"
        :order-from="orderFrom"
        :show-close-btn="true"
        @close="isconfirm =>{close(isconfirm)}"
      ></express-order-edit>
    </znl-dialog>
  </div>
</template>
<script>
import ExpressOrderEdit from "@c_modules/Logistics/EExpressOrderEdit";

export default {
  name: "EExpressOrderEditDialog",
  mixins: [],
  components: {
    ExpressOrderEdit
  },

  data() {
    return {
      dialogLoading: false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderGuid: {
      type: String,
      default: ""
    },
    orderNo: {
      type: String,
      default: ""
    },
    orderFrom: {
      type: String,
      default: ""
    }
  },

  methods: {
    close(isconfirm) {
      this.$emit("close", isconfirm);
    }
  }
};
</script>
