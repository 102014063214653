<template>
  <znl-dialog
    :visible="visible"
    height="500px"
    width="880px"
    title="采购入库明细"
    v-loading="logLoading"
    element-loading-text="数据加载中,请稍后..."
    :append-to-body="true"
    :close-on-click-modal="false"
    :is-footer-show="false"
    @close="()=>{$emit('close')}"
  >
    <stk-in-line-list
      ref="StkInLineList"
      page-position="top"
      :stkInLineGUIDS="stkInLineGUIDS"
      @page-loading="d=>{logLoading = d}"
    ></stk-in-line-list>
  </znl-dialog>
</template>

<script>
import StkInLineList from "@c_modules/Taobao/StkInLineList";

export default {
  name: "DialogStkInLineList",
  mixins: [],
  components: {
    StkInLineList
  },
  data() {
    return {
      logLoading: true
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    stkInLineGUIDS: {
      type: Array
    }
  },
  watch: {
    p_visible(val) {
      if (val) {
        this.onLoaded = true;
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.StkStockCheck {
  .znl-dialog {
    .el-dialog__body {
      padding-top: 0px;
    }
  }
  .znl-action-form {
    padding: 0;
  }
}
</style>
