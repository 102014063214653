<template>
  <!-- <znl-layout-spa :page-loading="pageLoading" znl-loading-text="页面加载中,请稍后...">
    <el-tabs
      value="VSoldOrderManage"
      class="nav-common-ui tab-btnstyle-box tab-manage-tab"
      v-model="activeName"
      v-show="!pageLoading"
      @tab-click="pageSearch"
    >
      <el-tab-pane label="已卖出的商品" name="SoldOrder" height="100%">
        <znl-layout-topbottom top-height="60%">
          <sold-order
            slot="znlTop"
            class="znlTop"
            :init-data="true"
            @selection-changed="onSoldOrderSelectionChanged"
            @page-loading="setClientSelectLoading"
            ref="SalesOrder"
          ></sold-order>
          <sold-order-line ref="soldOrderLine" slot="znlBottom" class="znlBottom"></sold-order-line>
        </znl-layout-topbottom>
      </el-tab-pane>
    </el-tabs>
  </znl-layout-spa>-->
  <div style="height: 100%; background: #fff;">
    <Split direction="vertical" @onDragEnd="onDragEnd">
      <SplitArea :size="65" :minSize="65">
        <sold-order
          ref="soldOrder"
          :init-data="true"
          @selection-changed="onSoldOrderSelectionChanged"
          @page-loading="setClientSelectLoading"
        ></sold-order>
      </SplitArea>
      <SplitArea :size="35" :minSize="35">
        <sold-order-line ref="soldOrderLine"></sold-order-line>
      </SplitArea>
    </Split>
  </div>
</template>
<script>
// v-table
import SoldOrder from "@c_modules/Taobao/SoldOrder";
import SoldOrderLine from "@c_modules/Taobao/SoldOrderLine";
import "vue-split-panel/dist/styles/vue-split-panel.css";

// 窗口大小监控mixin
import Resize from '~assets/scripts/mixins/resize';
import { isClient } from "~/lib/runtime";

export default {
  name: "VSoldOrderManage",
  mixins: [Resize],
  components: {
    SoldOrder,
    SoldOrderLine
  },
  watch: {
    pageLoading(val) {
      if (!val) {
        this.$el.classList.add("finishLoading");
      } else {
        this.$el.classList.remove("finishLoading");
      }
    },
    $route: "routeChangeFn"
  },
  data() {
    return {
      pageData: [],
      pageLoading: true,
      initData: {
        soldOrder: false
      },
      activeName: "SoldOrder",
      initStkOutBillNo: "",
      initSalesReturnBillNo: null
    };
  },
  methods: {
    onresize(){
      this.onDragEnd()
    },
    routeChangeFn(to, from) {
      if (to.name == "Sales/OrderManage" && to.params.poNumber != undefined) {
      }
    },
    onDragEnd() {
      this.$refs.soldOrder.inItTable();
      this.$refs.soldOrderLine.inItTable();
    },
    setClientSelectLoading(val) {
      this.pageLoading = val;
    },
    onSoldOrderSelectionChanged(lines) {
      this.$refs.soldOrderLine.onHeadSearch(lines);
    },
    pageSearch() {
      // RowGUID 辅助作用(没实际作用),用于改变条件,触发起始搜索
    },

    async getPageData() {}
  },
  async created() {}
};
</script>
<style lang="scss" scoped>
</style>
