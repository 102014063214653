<template>
  <!-- 寄件地址弹窗 -->
  <div>
    <znl-dialog
      title="淘宝发货"
      :visible="visible"
      subhead
      width="860px"
      height="520px"
      class="dialog-send-shipping-edit-box"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      element-loading-text="加载中，请稍后..."
      @close="onClose"
    >
      <el-row>
        <div>
          <label class="mini-title" style="padding-left:36px;">淘宝单号：</label>
          <label style=" margin-right: 15px;">{{editData.TidStr}}</label>
          <label class="mini-title">订单时间：</label>
          <label>{{editData.Created}}</label>
        </div>
        <div>
          <label class="mini-title">买家收货地址：</label>
          <label>{{editData.ReceiverState}}{{editData.ReceiverCity}}{{editData.ReceiverDistrict}} {{editData.ReceiverTown}} {{editData.ReceiverAddress}}，{{editData.ReceiverZip}}，{{editData.ReceiverName}}，{{editData.ReceiverMobile}}</label>
        </div>
      </el-row>

      <el-row style="height:300px" class="table-list-shipping">
        <znl-table
          ref="table"
          gridtype="base"
          box-class="table-list-shipping"
          :columns="columns"
          :item-source="itemSource"
        ></znl-table>
      </el-row>

      <el-row class="logitisc-service">
        <label class="mini-title" style="padding-left: 20px;">物流服务：</label>
        <el-radio v-model="editData.ServiceType" label="offline">自己联系物流</el-radio>
        <el-radio v-model="editData.ServiceType" label="without">无需物流</el-radio>
      </el-row>

      <el-row style="margin-top:10px;height: 30px;">
        <div v-show="editData.ServiceType==='offline'">
          <el-col :span="7">
            <znl-input
              title-width="80px"
              form-type="select"
              :select-options="companyOptions"
              size="mini"
              layout="left"
              :border="true"
              :is-must-fill="true"
              title="物流公司："
              width="220px"
              v-model="editData.CompanyCode"
              type="text"
              :clearable="true"
            ></znl-input>
          </el-col>
          <el-col :span="7">
            <znl-input
              title-width="80px"
              form-type="input"
              size="mini"
              layout="left"
              :border="true"
              :is-must-fill="true"
              title="运单号："
              width="220px"
              v-model="editData.ExpressOrderNo"
              type="text"
              :clearable="true"
            ></znl-input>
          </el-col>
        </div>
        <el-col :span="7"></el-col>
      </el-row>
      <el-row style="margin-top:8px;padding-left: 20px;">
        <znl-button
          style-type="main"
          @click="onSave"
          :disabled="this.disableSave"
          class="save-btn"
          :height="30"
        >
          <span>确认发货</span>
        </znl-button>
      </el-row>
    </znl-dialog>
  </div>
</template>
<script>
const CONFIG = {
  getOrderInfoUrl: "TaobaoOrder/GetOrderInfo",
  saveUrl: "TaobaoOrder/Ship"
};

const gridCols = [
  // {
  //   CTitle: "商品图片",
  //   BindField: "PicPath",
  //   IsShow: true,
  //   ColumnWidth: 80,
  //   DataType: 1
  // },
  {
    CTitle: "商品标题",
    BindField: "Title",
    IsShow: true,
    ColumnWidth: 240,
    DataType: 1
  },
  {
    CTitle: "商品属性",
    BindField: "SkuPropertiesName",
    IsShow: true,
    ColumnWidth: 140,
    DataType: 1
  },
  {
    CTitle: "数量",
    BindField: "Num",
    IsShow: true,
    ColumnWidth: 70,
    DataType: 2
  },
  {
    CTitle: "单价",
    BindField: "Price",
    IsShow: true,
    ColumnWidth: 70,
    DataType: 2
  },
  {
    CTitle: "小计金额",
    BindField: "Amount",
    IsShow: true,
    ColumnWidth: 70,
    DataType: 2
  },
  {
    CTitle: "优惠金额",
    BindField: "AdjustFee",
    IsShow: true,
    ColumnWidth: 70,
    DataType: 2
  },
  {
    CTitle: "应收金额",
    BindField: "TotalFee",
    IsShow: true,
    ColumnWidth: 70,
    DataType: 2
  },

  // {
  //   CTitle: "已配货量",
  //   BindField: "LinkedTotalQty",
  //   IsShow: true,
  //   ColumnWidth: 70,
  //   DataType: 1
  // },
  {
    CTitle: "已发货量",
    BindField: "StkOutTotalQty",
    IsShow: true,
    ColumnWidth: 70,
    DataType: 1
  },
  {
    CTitle: "商品货号",
    BindField: "GoodsNo",
    IsShow: true,
    ColumnWidth: 100,
    DataType: 1
  }
  // {
  //   CTitle: "已采购量",
  //   BindField: "StkInTotalQty",
  //   IsShow: true,
  //   ColumnWidth: 70,
  //   DataType: 1
  // },
  // {
  //   CTitle: "已询价量",
  //   BindField: "InquiryTotalQty",
  //   IsShow: true,
  //   ColumnWidth: 70,
  //   DataType: 1
  // }
];

export default {
  name: "ShippingEditDialog",
  mixins: [],
  components: {},

  data() {
    return {
      dialogLoading: true,
      itemSource: [],
      columns: [],

      disableSave: false,
      editData: {
        TidStr: "",
        ExpressOrderNo: "",
        CompanyCode: "",
        ServiceType: "offline",
        Created: "",
        ReceiverName: "",
        ReceiverMobile: "",
        ReceiverState: "",
        ReceiverCity: "",
        ReceiverDistrict: "",
        ReceiverTown: "",
        ReceiverAddress: "",
        ReceiverZip: ""
      },
      companyOptions: [
        { key: "SF", value: "顺丰速运" },
        { key: "ZJS", value: "宅急送" },
        { key: "STO", value: "申通快递" },
        { key: "ZTO", value: "中通快递" },
        { key: "YTO", value: "圆通速递" },
        { key: "YUNDA", value: "韵达快递" },
        { key: "EMS", value: "EMS" },
        { key: "POST", value: "中国邮政" },
        { key: "HTKY", value: "百世快递" },
        { key: "OTHER", value: "其他" },
        { key: "CYEXP", value: "长宇" },
        { key: "DTW", value: "大田" },
        { key: "YUD", value: "长发" },
        { key: "DISTRIBUTOR_13211725", value: "跨越速运" },
        { key: "DISTRIBUTOR_13222803", value: "中通快运" },
        { key: "PKGJWL", value: "派易国际物流77" },
        { key: "DISTRIBUTOR_13148625", value: "菜鸟大件-中铁配" },
        { key: "DISTRIBUTOR_13159132", value: "菜鸟大件-日日顺配" },
        { key: "YC", value: "远长" },
        { key: "DFH", value: "东方汇" },
        { key: "UNIPS", value: "发网" },
        { key: "MGSD", value: "美国速递" },
        { key: "WND", value: "WnDirect" },
        { key: "GZLT", value: "飞远配送 " },
        { key: "BHWL", value: "保宏物流" },
        { key: "DISTRIBUTOR_1710055", value: "邮政标准快递" },
        { key: "DISTRIBUTOR_13484485", value: "顺心捷达" },
        { key: "QFKD", value: "全峰快递" },
        { key: "TTKDEX", value: "天天快递" },
        { key: "EYB", value: "EMS经济快递" },
        { key: "UC", value: "优速快递" },
        { key: "DBKD", value: "德邦快递" },
        { key: "GTO", value: "国通快递" },
        { key: "SURE", value: "速尔快运" },
        { key: "FEDEX", value: "联邦快递" },
        { key: "SHQ", value: "华强物流" },
        { key: "UAPEX", value: "全一快递" },
        { key: "HOAU", value: "天地华宇" },
        { key: "BEST", value: "百世物流" },
        { key: "LB", value: "龙邦速递" },
        { key: "XB", value: "新邦物流" },
        { key: "FAST", value: "快捷快递" },
        { key: "NEDA", value: "能达速递" },
        { key: "BJRFD-001", value: "如风达配送" },
        { key: "DBL", value: "德邦物流" },
        { key: "YCT", value: "黑猫宅急便" },
        { key: "LTS", value: "联昊通" },
        { key: "CNEX", value: "佳吉快递" },
        { key: "HZABC", value: "飞远(爱彼西)配送" },
        { key: "XFWL", value: "信丰物流" },
        { key: "ESB", value: "E速宝" },
        { key: "GDEMS", value: "广东EMS" },
        { key: "BESTQJT", value: "百世快运" },
        { key: "POSTB", value: "邮政快递包裹" },
        { key: "QRT", value: "增益速递" }
      ]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tid: {
      type: String,
      default: 0
    }
  },

  methods: {
    onInit() {
      //
      this.columns = this.onInitCols(gridCols);
      this.$nextTick(() => {
        this.$refs.table.init();
        this.$emit("page-loading", false);
        this.dialogLoading = false;
      });
      this.onBindData();
    },

    onInitCols(columns) {
      // 图片
      let colIndex = _.findIndex(columns, col => col.BindField === "PicPath");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          title: "图片",
          width: 50,
          type: "panel",
          event: "",
          trigger(rowData, rowIndex, field) {
            return `<img src="${rowData.PicPath}" style="height: auto; width:100%;" />`;
          },
          render(rowData, rowIndex, field) {
            return `<img src="${rowData.PicPath}" />`;
          }
        });
      }
      return columns;
    },

    onBindData() {
      this.dialogLoading = true;
      this.$post(
        CONFIG.getOrderInfoUrl,
        { TidStr: this.tid },
        (data, logic) => {
          if (logic.code === 200) {
            this.itemSource = JSON.parse(data.Orders);
            this.editData.TidStr = data.TidStr;
            this.editData.Created = data.Created;
            this.editData.ReceiverName = data.ReceiverName;
            this.editData.ReceiverMobile = data.ReceiverMobile;
            this.editData.ReceiverState = data.ReceiverState;
            this.editData.ReceiverCity = data.ReceiverCity;
            this.editData.ReceiverDistrict = data.ReceiverDistrict;
            this.editData.ReceiverTown = data.ReceiverTown;
            this.editData.ReceiverAddress = data.ReceiverAddress;
            this.editData.ReceiverZip = data.ReceiverZip;
            this.editData.ExpressOrderNo = data.OutSid;
            this.editData.CompanyCode = data.CompanyCode;
          } else {
            let msg = logic.msg || "";
            this.showError(msg);
          }
        }
      ).finally(() => {
        this.dialogLoading = false;
      });
    },

    async onSave() {
      if (this.editData.ServiceType === "offline") {
        if (!this.hasValue(this.editData.ExpressOrderNo)) {
          this.showError("请输入运单号");
          return false;
        }
        if (!this.hasValue(this.editData.CompanyCode)) {
          this.showError("请选择物流公司");
          return false;
        }
      } else {
        let isok = await this.$confirm("确定不需要物流公司吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        });

        if (isok !== "confirm") {
          console.log("no need");
          return false;
        }
      }
      this.disableSave = true;
      this.$post(
        CONFIG.saveUrl,
        this.editData,
        (data, logic) => {
          this.disableSave = false;
          if (logic.code === 200) {
            this.showSuccess("发货成功");
            this.onClose(true);
          } else {
            let msg = logic.msg || "发货失败";
            this.showError(msg);
          }
        },
        () => {
          this.disableSave = false;
        }
      );
    },
    onClose(refresh = false) {
      this.$emit("close", refresh);
    }
  },
  mounted() {
    this.onInit();
  }
};
</script>
<style lang="scss">
.dialog-send-shipping-edit-box {
  .znl-dialog__body {
    font-size: 12px;
  }
  .mini-title {
    color: #a7a7a7;
  }
  .logitisc-service {
    margin-top: 8px;
    .el-radio {
      margin-right: 20px !important;
    }
    .el-radio__label {
      font-size: 12px;
    }
  }
  .znl-dialog__footer > div {
    width: 200px;
    float: right;
  }
}
</style>
