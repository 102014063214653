<template>
  <!-- 电子面单弹窗 -->
  <div>
    <znl-dialog
      title="取消电子面单"
      :visible="visible"
      subhead
      width="350px"
      height="240px"
      class="dialog-eexpress-order-cancel-box"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      element-loading-text="正在操作，请稍后..."
      @close="close"
    >
      <div>
        <el-row v-show="linkTitle!==''">
          <div class="znl-component-input znl-border-input left" style="width: 300px; height: 30px; line-height: 30px; ">
            <label class="znl-input-title" style="height: 30px; line-height: 30px; width: 70px; color:#606266">{{linkTitle}}</label>
            <div class="el-select znl-input-type znl-input-select isMustFill" tabindex="0" style="height: 30px; width: auto; line-height: 30px; margin-left: 70px;">
              {{orderNo}}
            </div>
          </div>
        </el-row>
        <el-row>
          <znl-input
            title-width="70px"
            form-type="select"
            :select-options="cancelReasonOptions"
            size="mini"
            layout="left"
            :border="true"
            :is-must-fill="true"
            title="取消原因："
            width="300px"
            @change="onReasonChange"
            v-model="cancelReasonSelected"
            type="text"
          ></znl-input>

        </el-row>
        <el-row v-show="this.cancelReasonSelected === '其他原因'">
          <znl-input
            title-width="70px"
            form-type="input"
            size="mini"
            layout="left"
            ref="cancelReasonTxt"
            :border="true"
            :is-must-fill="true"
            title="  "
            width="300px"
            :rows="3"
            v-model="cancelReason"
            type="textarea"
          ></znl-input>
        </el-row>
      </div>
      <el-row slot="footer">
        <znl-button style-type="main" :height="33" :width="60" @click="onConfirmCancel" :disabled="dialogLoading">取消面单</znl-button>
        <znl-button type="default" :height="33" :width="60" @click="close(false)" :disabled="dialogLoading">关闭</znl-button>
      </el-row>
    </znl-dialog>
  </div>
</template>
<script>

const CONFIG = {
  cancelOrderUrl: 'EOrderExpress/CancelOrder'
}

export default {
  name: 'EExpressOrderCancel',
  mixins: [],
  components: {},

  data () {
    return {
      dialogLoading: false,

      cancelReasonOptions: [
        {key: '信息填错了', value: '信息填错了'},
        {key: '迟迟未分配快递员', value: '迟迟未分配快递员'},
        {key: '不需要寄了', value: '不需要寄了'},
        {key: '其他原因', value: '其他原因'},
      ],
      cancelReason: '',
      cancelReasonSelected: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderGuid: {
      type: String,
      default: ''
    },
    orderNo: {
      type: String,
      default: ''
    },
    orderFrom: {
      type: String,
      default: ''
    },
    eorderId: {
      type: Number,
      default: 0
    }
  },

  computed: {
    linkTitle () {
      if (this.orderFrom === 'taobao') {
        return '淘宝订单号：'
      } else if (this.orderFrom === 'stkout') {
        return 'ERP发货单号：'
      }

      return ''
    }
  },

  methods: {
    close (refresh) {
      this.$emit("close", refresh)
    },

    onReasonChange () {
      if (this.cancelReasonSelected === '其他原因') {
        this.$refs.cancelReasonTxt.handleFocus();
      }
    },

    onConfirmCancel () {
      if (this.cancelReasonSelected === '') {
        this.showError('请选择取消原因')
        return false
      }
      if (this.cancelReasonSelected === '其他原因' && this.cancelReason === '') {
        this.showError('请输入取消原因')
        return false
      }
      let reason = this.cancelReasonSelected === '其他原因'? this.cancelReason : this.cancelReasonSelected;
      let param = {
        OrderNo: this.orderNo,
        OrderFrom: this.orderFrom,
        CancelReason:  reason
      }
      this.dialogLoading = true
      this.$post(CONFIG.cancelOrderUrl, param, (data, logic) => {
        if (logic.code === 200) {
          this.showSuccess('取消成功')
          this.close(true)
        } else {
          let msg = logic.msg || '取消失败'
          this.showError(msg)
        }
      }).finally(() => {
        this.dialogLoading = false
      })
    }
  }
}
</script>
