<template>
  <!-- 淘宝订单明细 -->
  <div class="module-wrapper m-stkout-order-line">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      box-class="m-stkout-order-line"
      :totalField="['Amount','TotalFee','Num']"
      :columns="columns"
      :item-source="itemSource"
      checkboxBindingKey="OidStr"
      @on-LinkedTotalQty-click="onLinkedTotalQtyClick"
      @on-StkOutTotalQty-click="onStkOutTotalQtyClick"
      @on-InquiryTotalQty-click="onInquiryTotalQtyClick"
      @on-StkInTotalQty-click="onStkInTotalQtyClick"
      :show-title-menus="true"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
      @on-link-model-click="onLinkModelClick"
      @on-select-stock-click="onSetLinkedClick"
      checkbox-binding-key="ID"
    ></znl-table>
    <!-- 配货 -->
    <dialog-stock-select
      slot="znl-dialog"
      :visible="stockSelectkVisible"
      v-if="stockSelectkVisible"
      :is-show-linked-qty-col="true"
      @confirm="setGridData"
      @close="()=>{stockSelectkVisible=false}"
    ></dialog-stock-select>

    <!-- 已入库明细 -->
    <dialog-stk-in-line-list
      slot="znl-dialog"
      :visible="stkInLineListVisible"
      v-if="stkInLineListVisible"
      :stkInLineGUIDS="stkInLineGUIDS"
      @close="()=>{stkInLineListVisible=false}"
    ></dialog-stk-in-line-list>

    <!-- 已出库明细 -->
    <dialog-stk-out-line-list
      slot="znl-dialog"
      :visible="stkOutLineListVisible"
      v-if="stkOutLineListVisible"
      :stkOutLineGUIDS="stkOutLineGUIDS"
      @close="()=>{stkOutLineListVisible=false}"
    ></dialog-stk-out-line-list>

    <!-- 已出库明细 -->
    <dialog-inquire-list
      slot="znl-dialog"
      :visible="inquireListVisible"
      v-if="inquireListVisible"
      :inquireGUIDS="inquireGUIDS"
      @close="()=>{inquireListVisible=false}"
    ></dialog-inquire-list>

    <!-- 已出库明细 -->
    <dialog-inquire-list
      slot="znl-dialog"
      :visible="inquireListVisible"
      v-if="inquireListVisible"
      :inquireGUIDS="inquireGUIDS"
      @close="()=>{inquireListVisible=false}"
    ></dialog-inquire-list>

    <!-- 已配货型号 -->
    <dialog-linked-stock-list
      slot="znl-dialog"
      :visible="linkedStockListVisible"
      v-if="linkedStockListVisible"
      :oidStr="this.currentRowOidStr"
      @confirm="delLinkedStock"
      @close="()=>{linkedStockListVisible=false}"
    ></dialog-linked-stock-list>

    <!-- 关联的ERP库存 -->
    <znl-dialog
      :visible="linkModels.show"
      height="200px"
      width="400px"
      title="关联的ERP库存"
      :is-footer-show="false"
      @close="()=>{linkModels.show = false}"
    >
      <div>
        <ul>
          <li v-for="(item, i) in linkModels.models" :key="i">{{item}}</li>
        </ul>
      </div>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { erpTableSetHandler } from "@scripts/methods/common";
import { mixin as common } from "~assets/scripts/methods/common";

import DialogStockSelect from "@c_modules/Stk/DialogStockSelect";
import DialogStkInLineList from "@c_modules/Taobao/DialogStkInLineList";
import DialogStkOutLineList from "@c_modules/Taobao/DialogStkOutLineList";
import DialogInquireList from "@c_modules/Taobao/DialogInquireList";
import DialogLinkedStockList from "@c_modules/Taobao/DialogLinkedStockList";

import { on } from "~assets/scripts/utils/dom";

const CONFIG = {
  PK: "OidStr",
  configURL: "TaobaoOrderLine/GetConfig",
  searchURL: "TaobaoOrder/GetOrderLine",
  setLinkedStock: "TaobaoOrder/SetLinkedStock",
  updateInquireQty: "TaobaoOrder/UpdateInquireQty",
  delLinkedStock: "TaobaoOrder/DelLinkedStock"
};

export default {
  name: "SalesOrderLine",
  config: CONFIG,
  mixins: [getCommonDataMixin, erpTableSetHandler, common],
  components: {
    DialogStockSelect,
    DialogStkInLineList,
    DialogStkOutLineList,
    DialogInquireList,
    DialogLinkedStockList
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      orderID: 0,
      currentRowOidStr: "",

      stockSelectkVisible: false,
      stkInLineListVisible: false,
      stkOutLineListVisible: false,
      inquireListVisible: false,
      linkedStockListVisible: false,
      stkInLineGUIDS: [], // 入库明细GUID
      stkOutLineGUIDS: [], //出货明细GUID
      inquireGUIDS: [], //询价GUID
      linkModels: {
        show: false,
        models: []
      }
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
    // 挂载一个window 事件，接收CS客户端派遣的event。接收参数为 PrimaryKey, data(supplierInquire)
    on(window, "onCSInqureSaved", e => {
      let { PrimaryKey, data } = e;
      this.setInquired(PrimaryKey, data);
    });
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("TaobaoOrderLine", CONFIG.configURL);
      }
      let columns = this.onInitCols(config.ColumnConfigs);
      this.columns = columns;
      this.$refs.table.init();
      this.$nextTick(() => {
        this.$emit("page-loading", false);
      });
      this.itemSource = [];
    },

    onInitCols(columns) {
      let tempConfig = [
        // {
        //   // width: 34,
        //   // type: "button",
        //   // title: "",
        //   // btnTxt: "配货",
        //   // isFrozen: true,
        //   // bindField:"SetStock",
        //   // click: row => {
        //   //   this.onSetLinkedClick(row);
        //   // },
        //   // formatter (rowData) {
        //   //   let stockQty = rowData.LinkedTotalQty
        //   //   console.log("stockQty",stockQty)
        //   //   if(stockQty>0)
        //   //   {
        //   //      return '<div class="redLink">配货</div>';
        //   //   }else{
        //   //      return '<div>配货</div>';
        //   //   }
        //   // }
        // }
      ];
      columns = tempConfig.concat(columns);

      // 图片
      let colIndex = _.findIndex(columns, col => col.BindField === "PicPath");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          // formatter (rowData, rowIndex, pageIndex, field) {
          //   console.log(rowData, 'formatter')
          //   return `<img src="${rowData.PicPath}" width="auto" height="100%" />`
          // },
          // classNames: ['el-icon-picture', 'el-icon-picture-outline'],
          title: "图片",
          width: 50,
          type: "panel",
          event: "",
          trigger(rowData, rowIndex, field) {
            return `<img src="${rowData.PicPath}" style="height: auto; width:100%;" />`;
          },
          render(rowData, rowIndex, field) {
            return `<img src="${rowData.PicPath}" />`;
          }
        });
      }

      colIndex = _.findIndex(
        columns,
        item => item.BindField === "LinkedTotalQty"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "on-LinkedTotalQty-click",
          className: "v-cell-link",
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "StkOutTotalQty"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "on-StkOutTotalQty-click",
          className: "v-cell-link"
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "InquiryTotalQty"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "on-InquiryTotalQty-click",
          className: "v-cell-link"
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "StkInTotalQty"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "on-StkInTotalQty-click",
          className: "v-cell-link"
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === 'LinkModels')
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "on-link-model-click",
          className: "v-cell-link",
          formatter (rowData) {
            let modelStr = rowData.LinkModels && rowData.LinkModels.join(", ")
            modelStr = modelStr || ""
            let title = rowData.LinkModels && rowData.LinkModels.join("\r\n") || ""
            return `<span title="${title}">${modelStr}</span>`
          }
        })
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "SelectStock");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "on-select-stock-click",
          className: "v-cell-link",
          formatter(rowData, rowIndex, field) {
              let stockQty= rowData.LinkedTotalQty
              if (stockQty > 0) {
                 return '<dev class="redLink">配货</dev>';
              }
              return '<dev>配货</span>';
          },
        });
      }
      return columns;
    },

    async onBindData() {
      if (!this.hasValue(this.orderID)) {
        return;
      }
      let param = _.extend(
        {},
        {
          Tid: this.orderID
        }
      );
      return await this.$post(CONFIG.searchURL, param, (data, logic) => {
        if (logic.code === 200) {
          let rst=data === undefined ? [] : data;
          _.each(rst, item => {
            item.SelectStock="配货"
          })
          this.itemSource = rst;
        }
      });
    },
    async onHeadSearch(orderID) {
      this.orderID = orderID;
      this.onBindData();
    },

    // 当CS客户端 保存询价成功后调用
    async setInquired(ASGuid, supplierInquire) {
      let activeRow = this.$refs.table.getActiveRow();
      let ojb = JSON.parse(supplierInquire);
      if (!this.hasValue(ojb.IQGUID)) return;
      let param = {
        InquireGuid: ojb.IQGUID,
        Num: ojb.Qty,
        OidStr: activeRow.OidStr
      };

      this.$post(CONFIG.updateInquireQty, param, (data, logic) => {
        if (logic.code === 200) {
          this.onBindData();
        }
      });
    },

    // 已配货量点击事件
    onLinkedTotalQtyClick(e) {
      if (e.field !== "LinkedTotalQty") {
        return;
      }
      if (
        this.hasValue(e.row.LinkedTotalQty) &&
        this.ConvertNumber(e.row.LinkedTotalQty) > 0 &&
        this.hasValue(e.row.LinkedJson)
      ) {
        let activeRow = this.$refs.table.getActiveRow();
        this.currentRowOidStr = activeRow.OidStr;
        this.$nextTick(() => {
          this.linkedStockListVisible = true;
        });
      }
    },
    // 已发货量点击事件
    onStkOutTotalQtyClick(e) {
      if (e.field !== "StkOutTotalQty") {
        return;
      }
      if (
        this.hasValue(e.row.StkOutTotalQty) &&
        this.ConvertNumber(e.row.StkOutTotalQty) > 0 &&
        this.hasValue(e.row.StkoutLineJson)
      ) {
        let ojb = JSON.parse(e.row.StkoutLineJson);
        this.stkOutLineGUIDS = [];
        _.each(ojb, item => {
          this.stkOutLineGUIDS.push(item.StkOutLineGuid);
        });
        this.$nextTick(() => {
          this.stkOutLineListVisible = true;
        });
      }
    },
    // 已询价量点击事件
    onInquiryTotalQtyClick(e) {
      if (e.field !== "InquiryTotalQty") {
        return;
      }
      if (
        this.hasValue(e.row.InquiryTotalQty) &&
        this.ConvertNumber(e.row.InquiryTotalQty) > 0 &&
        this.hasValue(e.row.InquiryJson)
      ) {
        let ojb = JSON.parse(e.row.InquiryJson);
        this.inquireLGUIDS = [];
        _.each(ojb, item => {
          this.inquireGUIDS.push(item.InquireGuid);
        });
        this.$nextTick(() => {
          this.inquireListVisible = true;
        });
      }
    },
    // 已入库量点击事件
    onStkInTotalQtyClick(e) {
      if (e.field !== "StkInTotalQty") {
        return;
      }
      if (
        this.hasValue(e.row.StkInTotalQty) &&
        this.ConvertNumber(e.row.StkInTotalQty) > 0 &&
        this.hasValue(e.row.StkinLineJson)
      ) {
        let ojb = JSON.parse(e.row.StkinLineJson);
        this.stkInLineGUIDS = [];
        _.each(ojb, item => {
          this.stkInLineGUIDS.push(item.StkInLineGuid);
        });
        this.$nextTick(() => {
          this.stkInLineListVisible = true;
        });
      }
    },

    // 配货
    onSetLinkedClick(cellData) {
      console.log("12312",cellData)
      this.$nextTick(() => {
        this.stockSelectkVisible = true;
      });
    },
    setGridData(rows) {
      let activeRow = this.$refs.table.getActiveRow();
      if (
        !this.hasValue(rows) ||
        rows.length <= 0 ||
        !this.hasValue(activeRow) ||
        activeRow.length <= 0
      )
        return;
      let param = [];
      _.each(rows, item => {
        let ojb = {
          StkStockGuid: item.StkGUID,
          Num: activeRow.Num,
          OidStr: activeRow.OidStr
        };
        if (this.hasValue(item.LinkStockQty)) {
          ojb.Num = item.LinkStockQty;
        } else {
          ojb.Num = activeRow.Num;
        }
        param.push(ojb);
      });
      this.$post(CONFIG.setLinkedStock, param, (rest, logic) => {
        if (logic.code === 200) {
          this.onBindData();
        }
      });
    },

    // 解除配货
    delLinkedStock(rows) {
      let activeRow = this.$refs.table.getActiveRow();
      if (
        !this.hasValue(rows) ||
        rows.length <= 0 ||
        !this.hasValue(activeRow) ||
        activeRow.length <= 0
      )
        return;
      let param = [];
      _.each(rows, item => {
        let ojb = {
          StkStockGuid: item.StkGUID,
          OidStr: activeRow.OidStr
        };
        param.push(ojb);
      });

      this.$post(CONFIG.delLinkedStock, param, (rest, logic) => {
        if (logic.code === 200) {
          this.onBindData();
        }
      });
    },

    // 关联ERP库存
    onLinkModelClick (rowData) {
      let row = rowData.row
      this.linkModels.models = row.LinkModels
      this.linkModels.show = true
    },

    inItTable() {
      this.$refs.table.init();
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("TaobaoOrderLine", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("TaobaoOrderLine", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("TaobaoOrderLine", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("TaobaoOrderLine");
    }
  }
};
</script>

<style>
.redLink {
  cursor: pointer;
  color: red;
  text-decoration: underline;
}
</style>
