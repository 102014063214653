<template>
  <!-- 淘宝订单 -->
  <div class="module-wrapper m-order-taobao">
    <znl-table
      ref="table"
      gridtype="base"
      :showCheck="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      v-loading="loading"
      :element-loading-text="loadingText"
      :totalField="['TotalFee','Payment','PostFee']"
      box-class="m-order-taobao"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :search-fields="onSearch"
      checkboxBindingKey="TidStr"
      :show-title-menus="true"
      @out-sid-click="outSidClick"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
      @current-row-change="onCurrentRowChange"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            form-type="select"
            :select-options="orderStatusOptions2"
            placeholder="订单状态"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="130px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.Status"
            type="text"
          ></znl-input>

          <znl-input
            form-type="input"
            placeholder="淘宝订单号"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="150px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.TidStr"
            type="text"
          ></znl-input>

          <znl-input
            form-type="select"
            :select-options="this.storeList"
            placeholder="店铺名称"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="100px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.StoreNameID"
            type="text"
          ></znl-input>

          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="订单日期从"
            size="mini"
            value-format="yyyy-MM-dd"
            layout="left"
            width="100px"
            :clearable="true"
            :border="true"
            inp-perc="100%"
            v-model="searchFields.StartCreated"
          ></znl-input>
          <span class="mr5">到</span>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="订单日期止"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="100px"
            :border="true"
            :clearable="true"
            inp-perc="100%"
            v-model="searchFields.EndCreated"
          ></znl-input>
          <znl-input
            form-type="input"
            placeholder="买家昵称"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="100px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.BuyerNick"
            type="text"
          ></znl-input>
          <znl-input
            form-type="input"
            placeholder="商品属性"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="120px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.SkuPropertiesName"
            type="text"
          ></znl-input>
          <znl-input
            form-type="input"
            placeholder="卖家备注"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="120px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.SellerMemo"
            type="text"
          ></znl-input>

          <znl-input
            form-type="select"
            :select-options="this.printStatus"
            placeholder="已打标?"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="70px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.IsPrintLabel"
            type="text"
          ></znl-input>

          <znl-input
            form-type="select"
            :select-options="this.stkOutStatus"
            placeholder="出库状态"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="76px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.StkOutStatus"
            type="text"
          ></znl-input>

          <znl-input
            form-type="select"
            :select-options="this.stkInStatus"
            placeholder="入库状态"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="76px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.StkInStatus"
            type="text"
          ></znl-input>
        </el-row>

        <el-row>
          <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
            <znl-button-menu @click="onSearch()">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
          </znl-button>
          <znl-button style-type="mac" @click="onStkOut()">
            <i class="iconfont icon-sell-wh_btn_ic"></i>
            <span>销售出库</span>
          </znl-button>
          <znl-button style-type="mac" @click="onStkIn()">
            <i class="iconfont icon-buy-wh_btn_ic"></i>
            <span>采购入库</span>
          </znl-button>
          <znl-button style-type="mac" @click="onMarketSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>市场查货</span>
          </znl-button>
          <!-- <znl-button style-type="mac" @click="onPrintOrder(true)">
            <i class="iconfont icon-print_btn_ic"></i>
            <span>打印订单</span>
          </znl-button>-->
          <znl-button
            style-type="mac"
            @click="onPrintOrder(false)"
            tip="点击向下箭头可以进行打印预览"
            :btns="orderPrintBtns"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>打印订单</span>
          </znl-button>

          <znl-button
            style-type="mac"
            @click="onPrintLabel()"
            tip="点击向下箭头可以进行打印预览"
            :btns="labelPrintBtns"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>打标签</span>
          </znl-button>

          <znl-button
            style-type="mac"
            @click="onBatchShippment()"
            v-if="false"
            tip="只有已输入快递单的单据，才能使用批量发货"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>批量发货</span>
          </znl-button>

          <znl-button style-type="mac" @click="onExpressOrderClick()" tip :btns="expressOrder.btns">
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>电子面单</span>
          </znl-button>

          <span style="margin-left:36px;"></span>
          <znl-button style-type="mac" @click="onShopSetting" tip="点击打开淘宝店铺设置">
            <i class="iconfont icon-table_set_btn_n"></i>
            <span>淘宝店铺设置</span>
          </znl-button>
          <znl-button
            style-type="mac"
            @click="onOrdersSync()"
            tip="同步当天店铺的订单"
            :btns="asyncOrderSet"
          >
            <i class="iconfont icon-table_refresh_btn_n"></i>
            <span>同步淘宝订单</span>
          </znl-button>
        </el-row>
      </div>
    </znl-table>

    <!-- 销售出库 -->
    <stk-out-edit
      :editvisible="showStkOutEdit"
      v-if="showStkOutEdit"
      edit-type="edit"
      @save-success="onRefresh(),onCleaSelectCount()"
      @close="showStkOutEdit=false"
    ></stk-out-edit>

    <!-- 采购入库 -->
    <stk-in-edit
      :editvisible="showStkInEdit"
      v-if="showStkInEdit"
      edit-type="edit"
      @save-success="onRefresh(),onCleaSelectCount()"
      @confirm="showStkInEdit=false"
      @close="showStkInEdit=false"
    ></stk-in-edit>

    <!-- 淘宝店铺设置 -->
    <shop-setting
      v-if="showShopSetting"
      :visible="showShopSetting"
      @close="showShopSetting = false"
    ></shop-setting>
    <znl-dialog
      title="打印帮助"
      :visible="showPrintHelp"
      width="480px"
      height="200"
      class="dialog-print-help"
      :is-footer-show="false"
      @close="showPrintHelp = false"
    >
      <div>
        <ol>
          <li>直接打印时，打印机需要设置为默认打印机</li>
          <li>请在windows里面，设置好打印机。比如选择选择好标签库的尺寸</li>
          <li>点击打印之后，打印指令会发送到软件，软件再调用windows进行打印，请稍等片刻</li>
        </ol>
      </div>
    </znl-dialog>
    <znl-dialog
      title="按自定义时间同步"
      :visible="showAsyncOrderByDate"
      width="350px"
      height="144"
      class="dialog-print-help"
      :append-to-body="true"
      :confirm-call-back="confirmCallBack"
      :close-on-click-modal="false"
      :is-footer-show="true"
      @close="showAsyncOrderByDate = false"
    >
      <div style="padding-left:15px">
        订单日期：
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="起始日期"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="asyncOrderStart"
        ></znl-input>&nbsp;到
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="截止日期"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="asyncOrderEnd"
        ></znl-input>
      </div>
    </znl-dialog>

    <!-- 电子面单 -->
    <express-order
      v-if="expressOrder.show"
      :visible="expressOrder.show"
      order-from="taobao"
      @close="expressOrderClose"
    ></express-order>

    <!-- 取消电子面单 -->
    <express-order-cancel
      v-if="expressOrder.showCancel"
      :visible="expressOrder.showCancel"
      order-from="taobao"
      :order-no="tid"
      @close="cancelExpressOrderClose"
    ></express-order-cancel>

    <!-- 月结账号设置 -->
    <monthly-account
      v-if="expressOrder.showMonthlyAccount"
      :visible="expressOrder.showMonthlyAccount"
      @close="() => {expressOrder.showMonthlyAccount = false}"
    ></monthly-account>

    <!-- 确认弹窗 -->
    <confirm-dialog
      :v-if="confirmDialog.show"
      :visible="confirmDialog.show"
      :button1-text="confirmDialog.button1Text"
      :button2-text="confirmDialog.button2Text"
      :button3-text="confirmDialog.button3Text"
      :message="confirmDialog.message"
      @close="() => {confirmDialog.show = false}"
      @button1-click="eOrderConfirmBack('print')"
      @button2-click="eOrderConfirmBack('preview')"
      @button3-click="eOrderConfirmBack('close')"
    ></confirm-dialog>

    <!-- 发货界面 -->
    <shipping-edit
      v-if="shipping.show"
      :visible="shipping.show"
      :tid="tid"
      @close="isrefresh => { shipping.show = false, isrefresh && onBindData()}"
    ></shipping-edit>

    <!-- 物流详情 -->
    <logistics-track
      v-if="LogisticsTrack.show"
      :visible="LogisticsTrack.show"
      :e-order-guid="LogisticsTrack.eOrderGuid"
      @close="val => {LogisticsTrack.show = false}"
    ></logistics-track>
  </div>
</template>
<script>
import {
  mixin as getCommonDataMixin,
  taobao,
} from "~assets/scripts/methods/getCommonData";
import { mixin as common } from "~assets/scripts/methods/common";
import { erpTableSetHandler } from "@scripts/methods/common";
import StkOutEdit from "@c_modules/Stk/StkOutEdit";
import StkInEdit from "@c_modules/Stk/StkInEdit";
import ShopSetting from "@c_modules/Taobao/ShopSetting";
import ExpressOrder from "@c_modules/Logistics/EExpressOrderEditDialog";
import ExpressOrderCancel from "@c_modules/Logistics/EExpressOrderCancel";
import MonthlyAccount from "@c_modules/Logistics/MonthlyAccount";
import ConfirmDialog from "@c_common/dialogs/confirm";
import ShippingEdit from "@c_modules/Logistics/ShippingEdit";
import LogisticsTrack from "@c_modules/Logistics/TrackInfo";

const CONFIG = {
  PK: "TidStr",
  configURL: "TaobaoOrder/GetConfig",
  searchURL: "TaobaoOrder/GetOrderInfoPageList",
  syncURL: "TaobaoShop/SyncOrder",
  printLabelTime: "TaobaoOrder/UpdatePrintLabelTime",
  getStoreNameList: "TaobaoShop/GetSetting",
  cancelOrderUrl: "EOrderExpress/CancelOrder",
  getImageBase64Url: "EOrderExpress/GetBase64String",
  batchShippmentUrl: "TaobaoOrder/BatchShippment",
  getProfitUrl: "TaobaoOrder/GetProfit",
};

export default {
  name: "SoldOrder",
  mixins: [getCommonDataMixin, common, erpTableSetHandler, taobao],
  config: CONFIG,
  components: {
    StkOutEdit,
    StkInEdit,
    ShopSetting,
    ExpressOrder,
    ExpressOrderCancel,
    MonthlyAccount,
    ConfirmDialog,
    ShippingEdit,
    LogisticsTrack,
  },

  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {
        BuyerNick: "", // 买家昵称
        Status: "", // 发货状态
        Tid: null, // 淘宝订单号
        StartCreated: "", //订单创建时间开始
        EndCreated: "", //订单创建时间结束
        IsPrintLabel: null, // 是否已打标
        StoreNameID: null, //淘宝账号用户ID
        StkOutStatus: "", // 出库状态
        StkInStatus: "", //入库状态
        SkuPropertiesName: "", // 商品属性
      },
      printStatus: [],
      storeList: [], //店铺名称集合
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false,
      loadingText: "数据加载中,请稍后...",
      stkOutStatus: [
        { key: 1, value: "未出" },
        { key: 2, value: "部分出" },
        { key: 3, value: "已出" },
      ],
      stkInStatus: [
        { key: 1, value: "未入" },
        { key: 2, value: "部分入" },
        { key: 3, value: "已入" },
      ],
      tid: "",
      currentEOrderGuid: "",
      //出库
      showStkOutEdit: false,
      //入库
      showStkInEdit: false,
      //淘宝店铺设置
      showShopSetting: false,
      // 打印预览
      labelPrintBtns: [
        {
          name: "打印预览",
          click: () => {
            this.onPrintLabel(true);
          },
        },
        {
          name: "打印设置",
          click: () => {
            this.showPrintSettings("TaobaoOrderLabel");
          },
        },
        {
          name: "帮助",
          click: () => {
            this.showPrintHelp = true;
          },
        },
      ],
      // 打印预览
      orderPrintBtns: [
        {
          name: "打印预览",
          click: () => {
            this.onPrintOrder(true);
          },
        },
        {
          name: "帮助",
          click: () => {
            this.showPrintHelp = true;
          },
        },
      ],
      showPrintHelp: false,

      // 电子面单
      expressOrder: {
        eorderId: 0,
        show: false,
        btns: [
          {
            name: "取消电子面单",
            click: () => {
              this.onCancelEOrder();
            },
          },
          {
            name: "设置月结账号",
            click: () => {
              this.onSetMonthlyAcct();
            },
          },
        ],
        showCancel: false,
        showMonthlyAccount: false,
      },
      asyncOrderSet: [
        {
          name: "按自定义时间同步",
          click: () => {
            this.onAsyncOrderByDate();
          },
        },
      ],
      showAsyncOrderByDate: false,
      asyncOrderStart: null,
      asyncOrderEnd: null,
      currentTime: new Date().toLocaleDateString(),
      confirmDialog: {
        show: false,
        message: "",
        button1Text: "直接打印",
        button2Text: "打印预览",
        button3Text: "取消",
      },
      kuaidiOptions2: [
        { key: "EMS", value: "ems" },
        { key: "百世快递", value: "huitongkuaidi" },
        { key: "申通快递", value: "shentong" },
        { key: "顺丰速运", value: "shunfeng" },
        { key: "圆通速递", value: "yuantong" },
        { key: "韵达快递", value: "yunda" },
        { key: "宅急送", value: "zhaijisong" },
        { key: "中通快递", value: "zhongtong" },
      ],
      shipping: {
        show: false,
      },
      LogisticsTrack: {
        show: false,
        eOrderGuid: null,
      },
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage("TaobaoOrder", CONFIG.configURL);
      }
      let columns = config.ColumnConfigs;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      columns = this.onInitCols(columns);

      this.printStatus.push({
        key: true,
        value: "已打",
      });
      this.printStatus.push({
        key: false,
        value: "未打",
      });

      this.columns = columns;
      this.$refs.table.init();
      this.initStoreList();
      this.$nextTick(() => {
        this.$emit("page-loading", false);
      });
    },

    onInitCols(columns) {
      let colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "Status"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          isReadOnly: true,
          options: this.orderStatusOptions,
        });
      }

      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "StkOutStatus"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          options: this.stkOutStatus,
          formatter(rowData, rowIndex, field) {
            var status = _.find(this.options, function (item) {
              return item.key === rowData.StkOutStatus;
            });
            if (status) {
              if (rowData.StkOutStatus !== 3) {
                //非已发
                return '<div class="red">' + status.value + "</div>";
              } else {
                return "<div>" + status.value + "</div>";
              }
            }
          },
        });
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "StkInStatus"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          options: this.stkInStatus,
          formatter(rowData, rowIndex, field) {
            var status = _.find(this.options, function (item) {
              return item.key === rowData.StkInStatus;
            });
            if (status) {
              if (rowData.StkInStatus !== 3) {
                //非已发
                return '<div class="red">' + status.value + "</div>";
              } else {
                return "<div>" + status.value + "</div>";
              }
            }
          },
        });
      }
      // 快递单号
      colIndex = _.findIndex(columns, (item) => item.BindField === "OutSid");
      let vm = this;
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "out-sid-click",
          formatter(rowData, rowIndex, field) {
            //0在途，1揽收，2疑难，3签收，4退签，5派件，6退回，7转投 等7个状态
            let names = [
              "在途",
              "揽收",
              "疑难",
              "签收",
              "退签",
              "派件",
              "退回",
              "转投",
            ];
            let name =
              ((rowData.TrackState || rowData.TrackState === 0) &&
                names[rowData.TrackState]) ||
              "";
            if (
              vm.hasValue(rowData.EOrderGuid) &&
              vm.hasValue(rowData.TrackState)
            ) {
              let html =
                '<a class="link" title="' + name + '"><i class="icon iconfont ';
              switch (rowData.TrackState) {
                case 3:
                  html += "icon-bomlist_save_btn1 color-success";
                  break;
                case 2:
                case 4:
                case 6:
                case 7:
                  html += "icon-table_error_ic red";
                  break;
                default:
                  break;
              }
              html += '"></i>' + rowData.OutSid + "</a>";
              return html;
            } else {
              return rowData.OutSid;
            }
            //
          },
        });
      }
      // colIndex = _.findIndex(columns, item => item.BindField === "CompanyCode");
      // if (colIndex > -1) {
      //   _.extend(columns[colIndex], {
      //     type: "select",
      //     isReadOnly: true,
      //     options: this.kuaidiOptions2
      //   });
      // }
      let mouseBtn = [
        {
          title: "销售出库",
          name: "onStkoutOpera",
          click: (row) => {
            this.onStkOut(row);
          },
        },
        {
          title: "采购入库",
          name: "onSktinOpera",
          click: (row) => {
            this.onStkIn([row]);
          },
        },
        {
          title: "添加电子面单",
          name: "onAddExpressOrder",
          click: (row) => {
            this.onExpressOrderClick(row);
          },
        },
        {
          title: "取消电子面单",
          name: "onCancelExpressOrder",
          click: (row) => {
            this.onCancelEOrder(row);
          },
        },
        {
          title: "发货",
          name: "onShipOne",
          click: (row) => {
            this.onShipOne(row);
          },
        },
      ];

      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true,
          canSearch: false,
        },
      ];
      return tempConfig.concat(columns);
    },

    // 初始化列表
    initStoreList() {
      this.$post(CONFIG.getStoreNameList, {}, (data, logic) => {
        if (logic.code === 200) {
          _.each(data, (item) => {
            if (this.hasValue(item.ShopName)) {
              this.storeList.push({
                key: item.TaobaoUserId,
                value: item.ShopName,
              });
            }
          });
        }
      });
    },
    // 搜索 绑定数据
    async onBindData(pageIndex = 1) {
      this.onCleaSelectCount();
      this.loading = true;
      let where = _.extend(this.searchFields, {
        PageIndex: this.toInt(pageIndex, 1),
        PageSize: this.pageSize,
      });
      return await this.$post(CONFIG.searchURL, where, (datas, logic) => {
        if (logic.code === 200) {
          this.pageIndex = pageIndex;
          this.itemSource = datas.ResultList;
          this.totalCount = datas.TotalCount;
          this.getProfit();
        }
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },

    // 获取利润
    async getProfit() {
      let tids = [];
      if (this.itemSource && this.itemSource.length > 0) {
        _.each(this.itemSource, (item) => {
          tids.push(item.TidStr);
        });
      }
      if (tids.length === 0) {
        return false;
      }

      this.$post(CONFIG.getProfitUrl, { TidStrList: tids }, (data, logic) => {
        if (logic.code === 200) {
          _.each(this.itemSource, (item) => {
            if (data[item.TidStr]) {
              this.$set(item, "ProfitAmount", data[item.TidStr]);
            }
          });
        } else {
          let msg = logic.msg || "获取利润失败";
          this.showError(msg);
        }
      });
    },

    // 主单单击，加载明细
    onCurrentRowChange(row) {
      if (row === null || row === undefined) {
        row = this.$refs.table.getActiveRow();
      }
      this.$emit("selection-changed", row.TidStr);
    },

    // 销售出库
    onStkOut(row = null) {
      if (row == null) {
        let rows = this.$refs.table.getCheckedRows();
        if (rows && rows.length > 0) {
          row = rows[0];
        }
      }
      if (row === null) {
        return this.$message({
          message: "请勾选订单!",
          type: "warning",
        });
      }
      this.$store.commit("setParamOrder", { taobaoOrder: row });
      this.$nextTick(function () {
        this.showStkOutEdit = true;
      });
      this.onCleaSelectCount();
    },

    // 采购入库
    onStkIn(rows = null) {
      if (rows === null) {
        rows = this.$refs.table.getCheckedRows();
      }

      if (rows === null || rows === undefined || rows.length === 0) {
        return this.$message({
          message: "请勾选订单!",
          type: "warning",
        });
      }
      this.$store.commit("setParamOrder", { taobaoOrder: rows });
      this.$nextTick(function () {
        this.showStkInEdit = true;
      });
      this.onCleaSelectCount();
    },

    // 市场查货
    async onMarketSearch() {
      let rows = this.$refs.table.getCheckedRows();
      if (rows.length === 0) {
        return this.$message({
          message: "请勾选订单!",
          type: "warning",
        });
      }
      let request = [];
      _.each(rows, (row) => {
        // 明细
        if (!this.hasValue(row.Orders)) {
          return;
        }
        let lines = JSON.parse(row.Orders);
        if (lines === undefined || lines === null || lines.length <= 0) {
          return;
        }
        _.each(lines, (item) => {
          request.push({
            Model: item.Title, // 型号，
            Qty: item.Num, // 数量，
            Oid: item.OidStr, // 明细ID
          });
        });
      });
      this.$nextTick(function () {
        this.openMarketSearch({ Requests: request, ClearRequests: false });
      });
    },
    // 打印订单
    onPrintOrder(showPreview) {
      // return this.$message({ message: "功能开发中.请等待下个版本." }); // 后续删除掉
      let rows = this.$refs.table.getCheckedRows();
      if (rows.length === 0) {
        return this.$message({
          message: "请勾选订单!",
          type: "warning",
        });
      }

      let orderList = [];
      let row = rows[0];
      let order = {
        tidStr: row.TidStr,
        created: row.Created,
        sellerMemo: row.SellerMemo === undefined ? "" : row.SellerMemo,
        buyerNick: row.BuyerNick,
        payTime: row.PayTime === undefined ? "" : row.PayTime,
        totalFee: row.TotalFee,
        payment: row.Payment,
        // 收货人信息
        receiverName: row.ReceiverName,
        receiverState: row.ReceiverState,
        receiverAddress: row.ReceiverAddress,
        receiverMobile: row.ReceiverMobile,
        receiverCity: row.ReceiverCity,
        receiverDistrict: row.ReceiverDistrict,
      };
      if (this.hasValue(row.Orders)) {
        let obj = JSON.parse(row.Orders);
        let index = 0;
        _.each(obj, (subItem) => {
          index++;
          let subInfo = {
            serialNo: index,
            oidStr: subItem.OidStr,
            model: subItem.Title,
            price: subItem.Price,
            qty: subItem.Num,
            skuPropertiesName: this.subStrStkValue(subItem.SkuPropertiesName),
            amount:
              this.ConvertNumber(subItem.Price) *
              this.ConvertNumber(subItem.Num),
            picPath: subItem.PicPath,
            itemNo: "", // 货号
            distribution: "", // 配货
          };

          // 将明细跟主单放到一起，平级
          let item = _.extend({}, order, subInfo);
          orderList.push(item);
        });
      } else {
        orderList.push(order);
      }

      console.log("print order", orderList);
      if (orderList.length > 0) {
        this.printECommerceOrder(orderList, showPreview);
        return true;
      } else {
        this.$message({
          message: "没有选中订单，或者选中的订单没有数据",
          type: "error",
        });
        return false;
      }
    },

    // 打印标签
    onPrintLabel(showPreview) {
      // return this.$message({ message: "功能开发中.请等待下个版本." }); // 后续删除掉
      let rows = this.$refs.table.getCheckedRows();
      if (rows.length === 0) {
        return this.$message({
          message: "请勾选订单!",
          type: "warning",
        });
      }
      let orderLine = [];
      let tidStrs = [];

      _.each(rows, (item) => {
        if (this.hasValue(item.Orders)) {
          let objLine = JSON.parse(item.Orders);
          let rowsNo = 1;
          _.each(objLine, (subItem) => {
            let receiverName = item.ReceiverName;
            if (this.hasValue(receiverName) && receiverName.length < 2) {
              receiverName = "你好 " + receiverName;
            }
            let info = {
              rowID: "(" + rowsNo + ")",
              tidStr: item.TidStr || item.tid_str,
              custName: item.BuyerNick || item.buyer_nick,
              created: new Date(item.Created)
                .toLocaleDateString()
                .replace(/\//g, "-"),
              oidStr: subItem.OidStr || subItem.oid_str,
              model: subItem.Title,
              price: subItem.Price,
              qty: subItem.Num,
              skuPropertiesName: this.subStrStkValue(subItem.SkuPropertiesName || subItem.sku_properties_name),
              amount:
                this.ConvertNumber(subItem.Price) *
                this.ConvertNumber(subItem.Num),
              receiverName: receiverName,
            };
            rowsNo++;
            orderLine.push(info);
          });
        }
        tidStrs.push(item.TidStr);
      });
      let param = {
        TableName: "TaobaoOrderLabel",
        DataSource: orderLine,
        DictCode: "TaobaoOrderLabel",
        ShowPreview: showPreview,
      };
      if (orderLine.length > 0) {
        this.printDataSource(param, () => {});
        // 更新打标时间/打标人
        if (!showPreview) {
          this.$post(
            CONFIG.printLabelTime,
            { TidStrs: tidStrs },
            (rest, logic) => {
              if (logic.code === 200) {
              }
            }
          );
        }
        return true;
      } else {
        this.$message({
          message: "没有选中订单，或者选中的订单没有数据",
          type: "error",
        });
        return false;
      }
    },

    onPrintHelp() {
      this.showPrintHelp = true;
    },

    // 淘宝店铺设置
    async onShopSetting() {
      this.showShopSetting = true;
    },
    // 同步淘宝订单by按时间
    async onAsyncOrderByDate() {
      this.showAsyncOrderByDate = true;
    },
    // 自定义日期同步订单
    async confirmCallBack() {
      let param = {};
      if (!this.hasValue(this.asyncOrderStart)) {
        return this.$message({ message: "请选择起始日期", type: "warning" });
      }
      if (!this.hasValue(this.asyncOrderEnd)) {
        return this.$message({ message: "请选择截止日期", type: "warning" });
      }
      this.showAsyncOrderByDate = false;
      param.SyncDateStart = this.asyncOrderStart;
      param.SyncDateEnd = new Date(this.asyncOrderEnd);
      // this.onOrdersSync(param);
      param.SyncDateEnd = new Date(new Date(param.SyncDateEnd.toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);

      this.loading = true;
      let loadingText = this.loadingText;
      this.loadingText = "正在同步中，请稍后...";

      try {
        console.log('开始同步')
        let count = await this.syncByTime(this.asyncOrderStart, 0, param.SyncDateEnd);

        this.$message({
          message: "同步完成，共同步了【" + count + "】条订单",
          type: "success",
        });
        this.onBindData();
      } catch (error) {
        this.$message({ message: error.message, type: "error" });
      } finally {
        setTimeout(() => {
          this.loading = false;
          this.loadingText = loadingText;
        }, 500);
      };
    },

    // 根据日期，逐个小时去同步
    async syncByTime (start, count, last) {
      start = new Date(start)
      if (start > last) {
        console.log('同步结束', start, last)
        return count;
      }
      let hours = start.getHours() + 1;
      let param = {
        SyncDateStart: start,
        SyncDateEnd: new Date((new Date(start)).setHours(hours))
      };

      console.log("param:--", param)

      await this.$post(CONFIG.syncURL, param, (data, logic) => {
        if (logic.code === 200) {
          count += data
          // this.loadingText = "正在同步中，请稍后（" + count + "）...";
          // await this.syncByTime(param.SyncDateEnd, count, last)
        } else {
          let msg = logic.msg || "同步失败，请稍后重试";
          throw msg
        }
      });

      await this.syncByTime(param.SyncDateEnd, count, last)

      return count;
    },

    // 同步淘宝订单
    async onOrdersSync(param) {
      this.loading = true;
      let loadingText = this.loadingText;
      this.loadingText = "正在同步中，请稍后...";
      if (param === null || param === undefined) {
        param = {}
      }

      return await this.$post(CONFIG.syncURL, param, (data, logic) => {
        // console.log(data, logic);
        if (logic.code === 200) {
          this.$message({
            message: "同步完成，共同步了【" + data + "】条订单",
            type: "success",
          });
          this.onBindData();
        } else {
          let msg = logic.msg || "同步失败，请稍后重试";
          this.$message({ message: msg, type: "error" });
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
          this.loadingText = loadingText;
        }, 500);
      });
    },

    async onSearch(params = null) {
      this.searchFields = _.extend({}, this.searchFields, params);
      this.onBindData(1);
    },

    // 刷新
    async onRefresh() {
      // await this.onBindData(this.pageIndex);
    },

    onBatchSave() {
      let rows = this.$refs.table.getChangedData();
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({ message: "数据没有发生变化", type: "warning" });
      } else {
        this.onSaveRows(rows);
      }
    },
    subStrStkValue(value) {
      let newValue = "";
      if (!this.hasValue(value)) return;
      if (value.indexOf("颜色分类:") > -1) {
        newValue = value.substr(5);
      } else if (value.indexOf("颜色:") > -1) {
        newValue = value.substr(3);
      }

      return newValue;
    },

    // 电子面单
    async onExpressOrderClick(row = null) {
      if (row === null) {
        let rows = this.$refs.table.getCheckedRows();
        if (rows.length > 0) {
          row = rows[0];
        }
      }
      if (row === null) {
        return this.$message({
          message: "请勾选订单!",
          type: "warning",
        });
      }

      if (row.EOrderType === "eprint" && this.hasValue(row.EOrderGuid)) {
        // let isok = await this.$confirm(
        //   `订单[${row.TidStr}]的电子面单已经生成，是否直接调用打印机打印？`,
        //   "提示",
        //   {
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消",
        //     type: "warning"
        //   }
        // );
        // if (isok) {
        //   this.$post(
        //     CONFIG.getImageBase64Url,
        //     { EOrderGuid: row.EOrderGuid },
        //     (data, logic) => {
        //       if (logic.code === 200 && data) {
        //         this.printBase64Image(data);
        //       } else {
        //         let msg = logic.msg || "获取电子面单失败，请重试";
        //         this.showError(msg);
        //       }
        //     }
        //   );
        // }
        this.currentEOrderGuid = row.EOrderGuid;
        this.confirmDialog.show = true;
        this.confirmDialog.message = `订单[${row.TidStr}]的电子面单已经生成，是否直接调用打印机打印？`;
        return false;
      }

      if (this.hasValue(row.EOrderGuid)) {
        this.showError(
          `该订单(${row.TidStr})已生产电子面单，如需重新生成，请先取消`
        );
        return false;
      }

      this.$store.commit("setParamOrder", row);
      this.expressOrder.show = true;
    },

    eOrderConfirmBack(type) {
      if (type === "close") {
        return false;
      }
      this.$post(
        CONFIG.getImageBase64Url,
        { EOrderGuid: this.currentEOrderGuid },
        (data, logic) => {
          if (logic.code === 200 && data) {
            if (type === "print") {
              this.printBase64Image(data);
            } else {
              this.printPreviewBase64Image(data);
            }
          } else {
            let msg = logic.msg || "获取电子面单失败，请重试";
            this.showError(msg);
          }
        }
      );
    },

    expressOrderClose(val) {
      this.expressOrder.show = false;
      if (val) {
        this.onBindData(this.pageIndex);
      }
    },

    // 取消电子面单
    async onCancelEOrder(row) {
      if (row === undefined || row === null) {
        let rows = this.$refs.table.getCheckedRows();
        if (rows && rows.length > 0) {
          row = rows[0];
        }
      }
      if (row === null || row === undefined) {
        return this.$message({
          message: "请勾选订单!",
          type: "warning",
        });
      }

      if (!this.hasValue(row.EOrderGuid)) {
        this.showError(`该订单(${row.TidStr})还未生成电子面单，无法取消`);
        return false;
      }

      if (row.EOrderType === "c") {
        // 弹窗取消
        this.tid = row.TidStr;
        this.expressOrder.showCancel = true;
      } else if (row.EOrderType === "eprint") {
        // 云打印取消. 判断依据：只有卖家已发货，并且运单号有值的，才能取消
        if (row.LogisticsType === 2) {
          this.showError("无需物流的订单无法取消");
          return false;
        }
        if (row.Status !== "WAIT_BUYER_CONFIRM_GOODS") {
          this.showError(`订单[${row.TidStr}]的状态不是[卖家已发货]，无法取消`);
          return false;
        }

        let isok = await this.$confirm(
          `您确定要取消订单[${row.TidStr}]的电子面单吗？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
        if (isok) {
          // 调用接口取消
          let param = {
            EOrderGuid: row.EOrderGuid,
            EOrderType: row.EOrderType,
          };
          this.$post(CONFIG.cancelOrderUrl, param, (data, logic) => {
            if (logic.code === 200) {
              this.showSuccess(`订单[${row.TidStr}]的电子面单取消成功`);
            } else {
              let msg = logic.msg || "取消失败";
              this.showError(msg);
            }
          });
        }
      }
    },
    cancelExpressOrderClose(val) {
      this.expressOrder.showCancel = false;
      if (val) {
        this.onBindData(this.pageIndex);
      }
    },

    // 设置月结账号
    onSetMonthlyAcct() {
      this.expressOrder.showMonthlyAccount = true;
    },

    // 批量发货
    onBatchShippment(rows = null) {
      if (rows === null || rows === undefined || rows.length === 0) {
        rows = this.$refs.table.getCheckedRows();
      }
      if (rows === null || rows === undefined || rows.length === 0) {
        this.showError("请勾选要发货的订单");
        return false;
      }
      let tids = [];
      _.forEach(rows, (row) => {
        if (
          this.hasValue(row.OutSid) &&
          this.hasValue(row.CompanyCode) &&
          row.Status === "WAIT_SELLER_SEND_GOODS"
        ) {
          tids.push(row.TidStr);
        }
      });

      if (tids.length === 0) {
        this.showError(
          "订单必须是[等待卖家发货]状态，并且已经录入了快递公司跟运单号"
        );
        return false;
      }

      this.loading = true;
      this.loadingText = "发货处理中，请稍后...";
      this.$post(
        CONFIG.batchShippmentUrl,
        tids,
        (data, logic) => {
          if (logic.code === 200 && data > 0) {
            this.showSuccess("发货成功");
            this.onBindData();
          } else {
            let msg = logic.msg || "发货失败";
            this.showError(msg);
          }
        },
        () => {
          this.loading = false;
        }
      ).finally(() => {
        this.loading = false;
      });
    },

    // 单个发货
    onShipOne(row) {
      if (row === undefined || row === null) {
        this.showError("请选择一个订单");
        return false;
      }
      switch (row.Status) {
        case "WAIT_BUYER_PAY":
          this.showError("订单为等待买家付款状态，无法发货");
          break;
        case "WAIT_SELLER_SEND_GOODS":
          break;
        case "WAIT_BUYER_CONFIRM_GOODS":
          break;
        default:
          break;
      }
      if (row.Status === "WAIT_BUYER_CONFIRM_GOODS") {
        // 已发货，判断是否有取消发货的操作
        if (!this.hasValue(row.EOrderGuid)) {
          this.showError(`该订单(${row.TidStr})已发货`);
          return false;
        }
      } else if (row.Status !== "WAIT_SELLER_SEND_GOODS") {
        this.showError("只有待发货状态的订单才可操作.");
        return false;
      }
      this.tid = row.TidStr;
      this.shipping.show = true;
    },

    outSidClick(rowData) {
      console.log(rowData, "out sid click");
      let row = rowData.row;
      if (row.EOrderGuid && row.OutSid) {
        this.LogisticsTrack.eOrderGuid = row.EOrderGuid;
        this.LogisticsTrack.show = true;
      }
    },

    // 清除选中行
    onCleaSelectCount() {
      this.$refs.table.cleanAllCheck();
    },
    inItTable() {
      this.$refs.table.init();
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("TaobaoOrder", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("TaobaoOrder", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("TaobaoOrder", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("TaobaoOrder");
    },
  },
};
</script>

<style lang="scss">
.dialog-print-help {
  ol {
    li {
      list-style: decimal;
    }
  }
}
</style>

