<template>
  <!-- 物流详情弹出 -->
  <div>
    <znl-dialog
      title="物流详情"
      :visible="visible"
      subhead
      width="720px"
      height="420px"
      class="dialog-logistics-detail-box"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      element-loading-text="正在操作，请稍后..."
      @close="close"
    >
      <el-row>
        <span class="ml20 title">运单号：</span>
        <!-- <span class="content">{{testData.lastResult.nu}}</span> -->
        <span class="content">{{info.ExpressNumber}}</span>

        <span class="ml20 title">物流公司：</span>
        <span class="content">{{info.ExpressCompany}}</span>

        <span class="ml20 title">状态：</span>
        <span class="content" v-if="!hasError">{{info.TrackStateName}}</span>
        <span class="red" v-if="hasError">{{info.TrackStateName}}</span>
      </el-row>
      <div style="overflow:auto; height: 350px;">
        <div class="no-detail" v-if="!hasItems(info.LastResult)">暂无物流详情</div>
        <ul class="status-list" v-if="hasItems(info.LastResult)">
          <li v-for="(item, index) in info.LastResult" :key="index">
            <span class="date">{{item.Date}}</span>
            <span class="week">{{item.Week}}</span>
            <span class="time">{{item.Time}}</span>
            <span class="text">{{item.Context}}</span>
          </li>
        </ul>
      </div>
    </znl-dialog>
  </div>
</template>
<script>

const CONFIG = {
  getUrl: 'Logistics/GetTrackInfo'
}

export default {
  name: "LogisticsTrackDialog",
  mixins: [],
  components: {},

  data() {
    return {
      dialogLoading: false,
      testData: {
        message: "变化",
        comOld: "",
        status: "polling",
        lastResult: {
          nu: "4305879670909",
          message: "ok",
          ischeck: "1",
          com: "yunda",
          status: "200",
          condition: "D01",
          data: [
            {
              time: "2020-06-01 19:17:46",
              context:
                "【深圳市】您的快件已被 汇洋站点 代签收，地址：振兴路新曼哈广场，如有疑问请电联快递员：林晓亮【18898564871】。",
              ftime: "2020-06-01 19:17:46"
            },
            {
              time: "2020-05-31 09:44:19",
              context:
                "【深圳市】广东深圳公司福田区汇洋分部 快递员 朱文君15766341785 正在为您派件【95114/95121/9501395546为韵达快递员外呼专属号码，请放心接听】",
              ftime: "2020-05-31 09:44:19"
            },
            {
              time: "2020-05-31 07:52:41",
              context:
                "【深圳市】已离开 广东深圳公司福田华强东分部；发往 广东深圳公司福田区前置仓分部",
              ftime: "2020-05-31 07:52:41"
            },
            {
              time: "2020-05-31 04:42:15",
              context:
                "【深圳市】已离开 广东深圳公司；发往 广东深圳公司福田华强东分部",
              ftime: "2020-05-31 04:42:15"
            },
            {
              time: "2020-05-31 04:31:25",
              context: "【深圳市】已到达 广东深圳公司",
              ftime: "2020-05-31 04:31:25"
            },
            {
              time: "2020-05-29 21:58:25",
              context: "【苏州市】已离开 江苏苏州分拨中心；发往 广东深圳公司",
              ftime: "2020-05-29 21:58:25"
            },
            {
              time: "2020-05-29 21:56:00",
              context: "【苏州市】已到达 江苏苏州分拨中心",
              ftime: "2020-05-29 21:56:00"
            },
            {
              time: "2020-05-29 16:32:27",
              context: "【无锡市】江苏主城区公司无锡惠山区前洲服务部 已揽收",
              ftime: "2020-05-29 16:32:27"
            },
            {
              time: "2020-05-29 16:32:27",
              context: "【无锡市】江苏主城区公司无锡惠山区前洲服务部 已揽收",
              ftime: "2020-05-29 16:32:27"
            },
            {
              time: "2020-05-29 16:32:27",
              context: "【无锡市】江苏主城区公司无锡惠山区前洲服务部 已揽收",
              ftime: "2020-05-29 16:32:27"
            },
            {
              time: "2020-05-29 16:32:27",
              context: "【无锡市】江苏主城区公司无锡惠山区前洲服务部 已揽收",
              ftime: "2020-05-29 16:32:27"
            }
          ],
          state: "3"
        },
        comNew: "",
        billstatus: "change",
        autoCheck: "0"
      },

      info: {
        TrackState: 0,
        ExpressNumber: '',
        ExpressCompany: '',
        LastResult: []
      },
      hasError: false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    eOrderGuid: {
      type: String,
      default: "29D6B37D-C455-4F62-B3D1-09E3B1D4369E"
    }
  },

  methods: {
    async onInit () {
      this.onBindData()
    },

    onBindData () {
      let param = {
        EOrderGuid: this.eOrderGuid
        // EOrderGuid: "29D6B37D-C455-4F62-B3D1-09E3B1D4369E"
      }
      this.$post(CONFIG.getUrl, param, (data, logic) => {
        if (logic.code === 200) {
          this.info = data
          //0在途，1揽收，2疑难，3签收，4退签，5派件，6退回，7转投 等7个状态
          let errInts = [2, 4, 6]
          if (errInts.indexOf(data.TrackState) > -1) {
            this.hasError = true
          }
        } else {
          this.showError(logic.msg || "加载失败")
        }
      })
    },

    hasItems (array) {
      return array && array.length > 0;
    },

    close(isconfirm) {
      this.$emit("close", isconfirm);
    }
  },

  mounted () {
    this.onInit()
  }
};
</script>
<style lang="scss">
.dialog-logistics-detail-box {
  .znl-dialog__body{
    font-size: 12px;
  }
  .ml20 {
    margin-left: 20px;
  }
  .title{
    // color: #d2d2d2;
  }
  .content{
    color: #000;
  }
  .status-list {
    margin-left: 20px;
    li{
      // list-style-type: circle;
      border-left: 1px solid #d9d9d9;
      vertical-align:top
    }
    li:before {
      content: '';
      border: 3px solid #dcdfe6;
      background-color: #d9d9d9;
      display: inline-flex;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      margin-left: -3px;
      margin-right: 10px;
      color: #dcdfe6;
    }
    li:hover{
      background: #ebeef5;
      -webkit-box-shadow:0px 3px 3px #ebeef5 ;
      -moz-box-shadow:0px 3px 3px #ebeef5 ;
      box-shadow:0px 3px 3px #ebeef5 ;
    }
    span {
      margin-left: 10px;
      display: inline-flex;
    }
    .date, .week {
      font-weight: bold;
    }
    .week {
      margin-left: 20px;
    }
    .text {
      margin-left: 20px;
      width: 460px;
      word-wrap: break-word;
    }
  }
  .hidden {
    visibility: hidden;
  }
  .no-detail{
    height: 100%;
    text-align: center;
    vertical-align: middle;
    padding-top: 20%;
  }
}
</style>
