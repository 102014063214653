<template>
  <znl-dialog
    :visible="visible"
    height="500px"
    width="880px"
    title="询价明细"
    v-loading="logLoading"
    element-loading-text="数据加载中,请稍后..."
    :append-to-body="true"
    :close-on-click-modal="false"
    :is-footer-show="false"
    @close="()=>{$emit('close')}"
  >
    <inquire-list
      ref="InquireList"
      page-position="top"
      :InquireGUIDS="inquireGUIDS"
      @page-loading="d=>{logLoading = d}"
    ></inquire-list>
  </znl-dialog>
</template>

<script>
import InquireList from "@c_modules/Taobao/InquireList";

export default {
  name: "DialogInquireList",
  mixins: [],
  components: {
    InquireList
  },
  data() {
    return {
      logLoading: true
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    inquireGUIDS: {
      type: Array
    }
  },
  watch: {
    p_visible(val) {
      if (val) {
        this.onLoaded = true;
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
</style>
