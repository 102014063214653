<template>
  <znl-dialog
    :visible="visible"
    height="500px"
    width="880px"
    title="已配货型号列表"
    v-loading="logLoading"
    element-loading-text="数据加载中,请稍后..."
    :append-to-body="true"
    :close-on-click-modal="false"
    :is-footer-show="true"
    @close="()=>{$emit('close')}"
  >
    <linked-stock-list
      ref="LinkedStockList"
      page-position="top"
      :oidStr="oidStr"
      @page-loading="d=>{logLoading = d}"
    ></linked-stock-list>
    <div slot="footer">
      <znl-button @click="onCancel" :height="33" :width="60">关闭</znl-button>
      <znl-button @click="onConfrim" :height="33" :width="74" style-type="main">解除配货</znl-button>
    </div>
  </znl-dialog>
</template>

<script>
import LinkedStockList from "@c_modules/Taobao/LinkedStockList";

export default {
  name: "DialogLinkedStockList",
  mixins: [],
  components: {
    LinkedStockList
  },
  data() {
    return {
      logLoading: true
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    oidStr: {
      type: String
    }
  },
  watch: {
    p_visible(val) {
      if (val) {
        this.onLoaded = true;
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit("close");
    },
    onConfrim(item) {
      let lists = [];
      if (!_.isUndefined(item)) {
        lists = this.$refs.LinkedStockList.onGetSelectedRows();
      } else {
        lists.unshift(item);
      }
      if (_.isUndefined(lists[0])) {
        return this.$message({
          message: "请勾选上要取消配货的库存型号!",
          type: "error"
        });
      } else {
        this.$confirm("确定要取消配货的库存型号?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.$emit("confirm", lists);
          })
          .finally(() => {
            this.$emit("close");
          });
      }
    }
  }
};
</script>

<style lang="scss">
</style>
