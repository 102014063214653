<template>
  <div class="module-dialog">
    <znl-table
      ref="table"
      gridtype="base"
      box-class="module-dialog"
      :columns="columns"
      :item-source="itemSource"
      v-loading="loading"
      :element-loading-text="loadingText"
    ></znl-table>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as common } from "~assets/scripts/methods/common";

const CONFIG = {
  searchURL: "StkOutLine/Search"
};

const gridCols = [
  {
    CTitle: "发货时间",
    BindField: "ShipTime",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 90,
    DataType: 1
  },
  {
    CTitle: "发货单号",
    BindField: "BillNo",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 100,
    DataType: 1
  },
  {
    CTitle: "发货员",
    BindField: "ShipperName",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "型号",
    BindField: "Model",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 130,
    DataType: 1
  },
  {
    CTitle: "品牌",
    BindField: "Brand",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "封装",
    BindField: "Packaging",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "年份",
    BindField: "MakeYear",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "数量",
    BindField: "Qty",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "含税价",
    BindField: "PriceInTax",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "未税价",
    BindField: "Price",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "小计金额",
    BindField: "Amount",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "客户名称",
    BindField: "CustomerName",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 130,
    DataType: 1
  },
  {
    CTitle: "包装",
    BindField: "MPQ",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "品质",
    BindField: "Quality",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  }
];

export default {
  name: "taobao_StkOutLineList",
  mixins: [getCommonDataMixin, common],
  config: CONFIG,
  components: {},

  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {
        StkOutLineGUIDS: "" // 出货明细GUID
      },
      loading: false,
      loadingText: "数据加载中,请稍后..."
    };
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    stkOutLineGUIDS: {
      type: Array
    }
  },

  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = {
          ColumnConfigs: gridCols
        };
      }
      let columns = config.ColumnConfigs;
      this.columns = columns;

      this.onInitCols(columns);

      this.$refs.table.init();
      this.$nextTick(() => {
        this.onBindData();
        this.$emit("page-loading", false);
      });
    },

    onInitCols(columns) {},

    // 搜索 绑定数据
    async onBindData(pageIndex = 1) {
      if (
        this.stkOutLineGUIDS == undefined ||
        this.stkOutLineGUIDS == null ||
        this.stkOutLineGUIDS.leng < 0
      ) {
        return;
      }
      this.loading = true;
      let where = {
        stkOutLineGUIDS: this.stkOutLineGUIDS
      };
      return await this.$post(CONFIG.searchURL, where, (result, logic) => {
        if (logic.code === 200) {
          this.itemSource = result;
        }
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    }
  }
};
</script>

<style lang="scss">
</style>

