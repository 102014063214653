<template>
  <div>
    <!-- 店铺列表弹出窗 -->
    <znl-dialog
      title="店铺列表"
      :visible="visible"
      subhead
      width="760px"
      height="350px"
      class="dialog-shop-setting-box"
      :close-on-click-modal="false"
      :is-footer-show="false"
      @close="val => $emit('close', val)"
    >
      <div class="dialog-table-outer-taobao" style="height: 100%">
        <znl-table
          ref="table"
          gridtype="base"
          :show-check="false"
          :columns="columns"
          :item-source="itemSource"
          v-loading="loading"
          element-loading-text="正在操作，请稍后..."
          box-class="dialog-table-outer-taobao"
          @init-data-status-click="onInitOrder"
        >
          <div slot="header" class="v-table-toolbar searchbox">
            <el-row>
              <znl-button style-type="mac" @click="onEdit()">
                <i class="iconfont icon-edit_btn_ic"></i>
                <span>编辑店铺</span>
              </znl-button>
              <znl-button style-type="mac" @click="onStkOut()">
                <i class="iconfont icon-delete_btn_ic"></i>
                <span>解绑店铺</span>
              </znl-button>
            </el-row>
          </div>
          <div slot="footer">
            <el-row style="padding-left:5px">
              <el-col :span="24 ">
                <a style="text-decoration:underline; font-size:12px;" @click="onSetTip()">★ 如何添加店铺 ？</a>
              </el-col>
            </el-row>
          </div>
        </znl-table>
      </div>

      <shop-setting-edit
        :visible="showEditBox"
        v-if="showEditBox"
        @close="val => {showEditBox = false,onBindData()}"
        @save-success="onBindData"
      ></shop-setting-edit>
    </znl-dialog>
  </div>
</template>
<script>
import ShopSettingEdit from "@c_modules/Taobao/ShopSettingEdit";
import { mixin as common } from "~assets/scripts/methods/common";

const CONFIG = {
  searchUrl: "TaobaoShop/GetSetting",
  delUrl: "TaobaoShop/DeleteSetting",
  initDataUrl: "TaobaoShop/InitOrder"
};

const gridCols = [
  {
    CTitle: "店铺名称",
    BindField: "ShopName",
    IsShow: true,
    ColumnWidth: 160,
    DataType: 1
  },
  // {
  //   CTitle: "淘宝昵称",
  //   BindField: "TaobaoNick",
  //   IsShow: true,
  //   ColumnWidth: 120,
  //   DataType: 1
  // },
  // {
  //   CTitle: "订单服务授权",
  //   BindField: "OrderAuthStr",
  //   IsShow: true,
  //   ColumnWidth: 85,
  //   DataType: 1
  // },
  // {
  //   CTitle: "商品服务授权",
  //   BindField: "GoodsAuthStr",
  //   IsShow: true,
  //   ColumnWidth: 85,
  //   DataType: 1
  // },
  {
    CTitle: "店铺有效日期",
    BindField: "StartTime",
    IsShow: true,
    ColumnWidth: 86,
    DataType: 4
  },
  {
    CTitle: "店铺截止日期",
    BindField: "EndTime",
    IsShow: true,
    ColumnWidth: 86,
    DataType: 4
  },
  {
    CTitle: "订单初始化",
    BindField: "InitDataStatusStr",
    IsShow: true,
    ColumnWidth: 86,
    DataType: 1
  },
  {
    CTitle: "绑定平台",
    BindField: "Platform",
    IsShow: true,
    ColumnWidth: 60,
    DataType: 1
  }
];

export default {
  name: "shop-setting",
  mixins: [common],
  components: {
    ShopSettingEdit
  },

  data() {
    return {
      columns: [],
      itemSource: [],
      loading: true,
      showEditBox: false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async onInit() {
      let columns = gridCols;
      columns = this.initCols(columns);
      this.columns = columns;
      this.onBindData();
      this.$nextTick(() => {
        this.$refs.table.init();
        this.loading = false;
        this.$emit("page-loading", false);
      });
    },

    initCols(cols) {
      let statusItem = _.find(
        cols,
        item => item.BindField === "InitDataStatusStr"
      );
      if (statusItem) {
        _.extend(statusItem, {
          width: 80,
          type: "button",
          isDeal: false,
          canSearch: false,
          btnTxt: "btn txt",
          title: "数据初始化",
          className: "init-data",
          btnRender: (row, col) => {
            let className = "init-data-opera";
            if (row.InitDataStatus === -1 || row.InitDataStatus === 4) {
              className = "init-data-status-readonly";
              if (row.InitDataStatus === -1) {
                className += " red";
              }
            }
            return `<div class="${className}">${row.InitDataStatusStr}</div>'`;
          },
          showButton(row) {
            return true;
          },
          click: (row, index) => {
            this.onInitOrder(row);
            return true;
          }
        });
      }

      let colIndex = _.findIndex(cols, item => item.BindField === "GoodsAuthStr");
      if (colIndex > -1) {
        _.extend(cols[colIndex], {
          formatter(rowData, rowIndex, field) {
            let className = rowData.IsGoodsAuth? 'authed': 'unauth'
            return `<div class="${className}" title="${rowData.GoodsAuthStr}">${rowData.GoodsAuthStr}</div>"`;
          }
        });
      }

      colIndex = _.findIndex(cols, item => item.BindField === "OrderAuthStr");
      if (colIndex > -1) {
        _.extend(cols[colIndex], {
          formatter(rowData, rowIndex, field) {
            let className = rowData.IsOrderAuth? 'authed': 'unauth'
            return `<div class="${className}" title="${rowData.OrderAuthStr}">${rowData.OrderAuthStr}</div>"`;
          }
        });
      }

      return cols;
    },

    async onBindData() {
      this.$post(CONFIG.searchUrl, {}, data => {
        this.itemSource = data;
      });
    },

    async onEdit() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row.ID)) {
        return this.$message({
          message: "请选中要编辑的店铺",
          type: "warning"
        });
      } else {
        let info = { info: row };
        this.$store.commit("setParamOrder", info);
        this.$nextTick(function() {
          this.showEditBox = true;
        });
      }
    },
    async onStkOut() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row.ID)) {
        return this.$message({
          message: "请选中要解绑的店铺",
          type: "warning"
        });
      } else {
        this.$confirm("确定要解绑选中的店铺?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          var vm = this;
          vm.loading = true;
          return this.$post(CONFIG.delUrl, row, (data, logic) => {
            vm.loading = false;
            if (logic.code !== 200) {
              this.$message({ message: logic.msg, type: "error" });
            } else {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData();
            }
          }).finally(() => {
            vm.loading = false;
          });
        });
      }
    },

    async onInitOrder(row) {
      let param = { TaobaoUserId: row.TaobaoUserId };
      if (row.InitDataStatus === -1) {
        this.$message({
          message: "请先对店铺进行服务授权,再初始化数据!",
          type: "warning"
        });
        return false;
      }
      this.loading = true;
      this.$post(CONFIG.initDataUrl, param, (data, logic) => {
        if (logic.code === 200) {
          this.$message({
            message: data ? "已加入初始化队列" : "加入初始化队列失败，请重试",
            type: data ? "success" : "error"
          });
        } else {
          this.$message({
            message: logic.msg || "加入初始化队列失败，请重试",
            type: "error"
          });
        }
        this.onBindData();
      }).finally(() => {
        this.loading = false;
      });
    },
    onSetTip() {
      this.$message({
        message: "请联系您的专属业务员或拨打客服热线(0755-83685522)进行咨询.",
        type: "success"
      });
    }
  },

  async mounted() {
    await this.onInit();
  }
};
</script>
<style lang="scss">
.dialog-shop-setting-box {
  .init-data {
    .table-btn {
      text-decoration: none;
    }
  }
  .init-data-status-readonly {
    color: #000;
    text-decoration: none;
    cursor: default;
  }
  .init-data-opera {
    color: #0092dd;
    text-decoration: underline;
    cursor: pointer;
  }
  .red {
    color: red;
  }
  .authed {
    color: green;
  }
  .unauth {
    color: red;
  }
}
</style>
