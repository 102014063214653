<template>
  <div>
    <!-- 店铺列表弹出窗 - 新增、编辑 -->
    <znl-dialog
      title="店铺设置 - 编辑"
      :visible="visible"
      subhead
      height="400px"
      width="760px"
      class="dialog-shop-setting-edit-box"
      :close-on-click-modal="false"
      :is-footer-show="true"
      v-loading="dialogLoading"
      element-loading-text="正在操作，请稍后..."
      @close="close"
    >
      <div>
        <el-row class="tips" v-if="false">
          <el-col :span="3">
            <div class="grid-content bg-purple text-right">提示：</div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple">
              首先，需要在淘宝服务市场，购买以下的服务，才能使用该接口。
              <br />第一个：
              <strong>网店订单助手</strong>。
              <br />第二个：
              <strong>商品店助手</strong>。
              <br />购买成功后，单击以下【点击授权】链接，将会打开淘宝授权界面，使用您刚购买服务的淘宝账号进行登录并授权即可。
            </div>
          </el-col>
        </el-row>


        <el-row class="tips">
          <el-col :span="3">
            <div class="grid-content bg-purple text-right">提示：</div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple">
              1. 输入店铺名称，点击保存。店铺名称必须跟你淘宝店铺的名称一致
              <br />
              2. 需要在淘宝服务市场，购买一个牛宝服务，才能使用该接口。该服务的费用由<strong>淘宝</strong>收取.
              <br />
              3. 购买成功后，需要点击授权链接，用购买服务的淘宝账号登录进行授权.
              <br /><b>注意：</b>需要同步后续的同步订单，只能同步您购买这个服务的店铺的订单.
            </div>
          </el-col>
        </el-row>

        <el-row>
          <znl-input
            ref="ShopName"
            title-width="100px"
            layout="left"
            form-type="input"
            :readonly="false"
            :disabled="false"
            :border="true"
            :is-must-fill="true"
            tabindex="8"
            v-model="editData.ShopName"
            width="350px"
            placeholder="请输入您的店铺名称"
            title="*店铺名称："
          ></znl-input>
        </el-row>

        <el-row>
          <znl-input
            ref="PlatForm"
            title-width="100px"
            layout="left"
            form-type="select"
            :select-options="platFormOptions"
            :readonly="false"
            :disabled="false"
            :border="true"
            :is-must-fill="true"
            tabindex="8"
            v-model="editData.PlatForm"
            width="180px"
            title="*绑定平台："
          ></znl-input>
        </el-row>

        <el-row class="form-title" style="margin-top:15px;">
          <!-- 商品授权： -->
          <!-- <znl-input
            ref="OrderAuthUrl"
            title-width="100px"
            layout="left"
            form-type="input"
            :readonly="false"
            :disabled="false"
            :border="true"
            tabindex="8"
            v-model="editData.OrderAuthUrl"
            width="380px"
            title="订单授权后网址："
          ></znl-input>-->
          <label class="left">订单服务购买：</label>
          <!-- <label class="unauth" v-if="!editData.IsOrderAuth">未授权</label>
          <label class="authed" v-if="editData.IsOrderAuth">已授权</label> -->
          <!-- <label :class="editData.IsOrderAuth? 'authed' : 'unauth'">{{editData.OrderAuthStr}}</label> -->
          <a :href="item.url" target="_blank" class="link mr20" v-for="item in authConfig.orderServices" :key="item">{{item.name}}</a>
          <!-- <label class="link">点击进行授权</label> -->
        </el-row>

        <el-row class="form-title" style="margin-top:15px;">
          <label class="left">订单服务授权：</label>
          <a href="https://oauth.taobao.com/authorize?response_type=code&client_id=12686282&redirect_uri=https://tb.xqcrm.com/api/open/auth&view=web&state=v4_platform_641" target="_blank" class="link mr20">点击进行授权</a>
        </el-row>

        <el-row class="form-title" v-if="false">
          <!-- <znl-input
            ref="GoodsAuthUrl"
            title-width="100px"
            layout="left"
            form-type="input"
            :readonly="false"
            :disabled="false"
            :border="true"
            tabindex="8"
            v-model="editData.GoodsAuthUrl"
            width="380px"
            title="商品授权后网址："
          ></znl-input>-->
          <label class="left">商品服务：</label>
          <!-- <label class="unauth" v-if="!editData.IsGoodsAuth">未授权</label>
          <label class="authed" v-if="editData.IsGoodsAuth">已授权</label> -->
          <label :class="editData.IsGoodsAuth? 'authed' : 'unauth'">{{editData.GoodsAuthStr}}</label>
          <a :href="authConfig.goodsServiceBuyUrl" target="_blank" class="link ml50">购买服务</a>
          <a
            :href="authConfig.goodsAuthUrl"
            target="_blank"
            class="link"
            @click="event => {onOAuthClick(event, 'item')}"
          >点击授权</a>
        </el-row>
      </div>
      <div slot="footer" class="footer">
        <znl-button
          :height="30"
          :width="50"
          style-type="main"
          @click.stop="onSave"
          :disabled="saveloading"
        >保存</znl-button>
        <znl-button :height="30" :width="50" @click.stop="close">关闭</znl-button>
      </div>
    </znl-dialog>
  </div>
</template>
<script>
const CONFIG = {
  saveUrl: "TaobaoShop/UpdateSetting",
  getOAuthUrl: "TaobaoOAuth/GetOAuthUrl",
  getSettingsUrl: "TaobaoShop/GetSettingByID"
};
import { mixin as common } from "~assets/scripts/methods/common";

export default {
  name: "shop-setting",
  mixins: [common],
  components: {},

  data() {
    return {
      dialogLoading: true,
      saveloading: false,

      authConfig: {
        // orderAuthUrl: "https://oauth.taobao.com/authorize?response_type=token&client_id=23700513&view=web",
        // goodsAuthUrl: "https://oauth.taobao.com/authorize?response_type=token&client_id=12290165&view=web",
        orderAuthUrl: "javascript:void(0)",
        goodsAuthUrl: "javascript:void(0)",
        orderServiceBuyUrl: "https://tb.cn/0qtLDDw",
        goodsServiceBuyUrl: "https://tb.cn/0LaRuDw",
        orderServices: [
          { name: "一个月", url: "https://c.tb.cn/Y4.fiQw1"},
          { name: "一个季度", url: "https://c.tb.cn/Y4.f70P0"},
          { name: "半年", url: "https://c.tb.cn/Y4.f9c6f"},
          { name: "一年", url: "https://c.tb.cn/Y4.f8C4M"},
        ]
      },

      editData: {
        ID: null,
        ShopName: "",
        PlatForm: "taobao",
        OrderAuthUrl: "",
        GoodsAuthUrl: "",
        OrderAuthStr: '',
        GoodsAuthStr: '',
        IsOrderAuth: false,
        IsGoodsAuth: false
      },

      platFormOptions: [{ key: "taobao", value: "淘宝" }],
      myloop: null,
      openCount: 0
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async onInit() {
      this.dialogLoading = false;
      this.$emit("page-loading", false);

      if (this.$store.state.paramOrder.info) {
        let info = this.$store.state.paramOrder.info;
        this.editData = _.extend(this.editData, info);
        this.$store.commit("setParamOrder", {});
      }
    },
    getTaboAuthorizeInfo(str) {
      str = str.replace(/\s+/g, "");
      str = str.replace(/.*?\#/, "");
      const arr = str.split("&");
      const object = {};
      arr.map(item => {
        const splitArr = item.split("=");
        object[splitArr[0]] = splitArr[1];
      });
      return object;
    },

    async onSave() {
      if (!this.hasValue(this.editData.ShopName.trim())) {
        return this.$message({
          message: "请填写您的店铺名称",
          type: "warning"
        });
      }
      // if (
      //   !this.hasValue(this.editData.OrderAuthUrl.trim()) &&
      //   !this.hasValue(this.editData.GoodsAuthUrl.trim())
      // ) {
      //   return this.$message({
      //     message: "请复制正确授权成功后的【订单或商品服务授权网址】",
      //     type: "warning"
      //   });
      // }
      // let info = this.getTaboAuthorizeInfo(this.editData.OrderAuthUrl.trim());
      // if (
      //   this.hasValue(this.editData.OrderAuthUrl.trim()) &&
      //   (!this.hasValue(info.access_token) ||
      //     !this.hasValue(info.taobao_user_id))
      // ) {
      //   return this.$message({
      //     message: "请复制正确授权成功后的【订单服务授权网址】",
      //     type: "warning"
      //   });
      // }
      // let goodsInfo = this.getTaboAuthorizeInfo(this.editData.GoodsAuthUrl);
      // if (
      //   this.hasValue(this.editData.GoodsAuthUrl.trim()) &&
      //   (!this.hasValue(goodsInfo.access_token) ||
      //     !this.hasValue(goodsInfo.taobao_user_id))
      // ) {
      //   return this.$message({
      //     message: "请复制正确授权成功后的【商品服务授权网址】",
      //     type: "warning"
      //   });
      // }
      var vm = this;
      vm.dialogLoading = true;
      return this.$post(CONFIG.saveUrl, this.editData, (data, logic) => {
        vm.dialogLoading = false;
        if (logic.code !== 200) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$emit("save-success");
          this.$message({ message: "保存成功", type: "success" });
          this.timmer = setTimeout(() => {
            this.$emit("close");
          }, 500);
        }
      }).finally(() => {
        vm.dialogLoading = false;
      });
    },

    // 获取授权地址
    async onOAuthClick(event, authType) {
      event.preventDefault();
      if (authType !== "order" && authType !== "item") {
        this.$message({ message: "授权类型有误", type: "error" });
        return false;
      }

      let param = {
        ID: this.editData.ID,
        AuthType: authType
      };
      this.$post(CONFIG.getOAuthUrl, param, (url, logic) => {
        if (logic.code === 200) {
          this.openUrl(url);
          if (this.myloop == null) {
            this.myloop = setInterval(() => {
              this.getShopSetting();
            }, 5000);
          }
        } else {
          let msg = logic.msg || "获取授权地址失败";
          this.$message({ message: msg, type: "error", duration: 20 * 1000 });
        }
      });
    },

    openUrl(url) {
      this.openCount++;
      (function() {
        window.el = document.createElement("a");
        window.el.href = url;
        window.el.target = "_blank";
        document.body.appendChild(window.el);
        window.el.click();
        document.body.removeChild(window.el);
      })();
    },

    getShopSetting() {
      this.$post(CONFIG.getSettingsUrl, { ID: this.editData.ID }, data => {
        _.extend(this.editData, data);
      });
    },

    close(val) {
      clearInterval(this.myloop);
      this.$emit("close", val);
    }
  },

  async mounted() {
    await this.onInit();
  },
  beforeDestroy () {
    if (this.myloop) {
      clearInterval(this.myloop);
    }
  }
};
</script>
<style lang="scss">
.dialog-shop-setting-edit-box {
  .link {
    font-size: 12px;
    text-decoration: underline;
    margin-left: 5px;
    color: #2855f8;
  }
  .tips {
    font-size: 12px;
  }
  .text-right {
    text-align: right;
  }
  .form-title {
    font-size: 12px;
    .left {
      width: 100px;
      text-align: right;
    }
    > label,
    .item {
      display: block;
      float: left;
    }
    .authed {
      color: green;
    }
    .unauth {
      color: red;
    }
    .ml50 {
      margin-left: 50px;
    }

    .mr20 {
      margin-left: 20px;
    }
  }
}
</style>
